import React from "react";

function ContentGuidelines() {
  return (
    <div className="p-6 min-h-screen text-white">
      <div className="text-center text-[20px] px-[20%]">
        <br />
        <span className="text-white text-center text-3xl font-bold mb-4">
          Content &amp; Creation <br />
          Guidelines
        </span>
      </div>
      <br />
      <br />

      <div className=" px-[20%] text-base">
        <p>
          When trying to decide what counts as a benefit that can be provided to
          your subscribers, our team does an analysis to determine what
          subscribers are paying for and the value they receive. We also have to
          make sure people do not circumvent our guidelines by sharing
          prohibited benefits of Blaze that are not identified on Blaze.
          <br />
          <br />
          <strong>Illegal creations and benefits</strong>
          <br />
          <br />
          Content that is illegal under applicable U.S. federal and state laws,
          including but not limited to the Communications Decency Act. Refer to
          the relevant U.S. laws for further details. You can also get
          information about the same in our Terms and Conditions. Please
          understand that terms and conditions are subject to change and it can
          not be considered as an exhaustive information guide in context of
          U.S. federal and state laws.
          <br />
          <br />
          Here are some types of benefits that we do not allow anywhere on
          Blaze. In some of these cases, we will also report the account and
          work with law enforcement to identify the source. Benefits that are
          not allowed include:
        </p>
        <ul className="list-disc pl-5 text-left  mt-0 mb-2.5">
          <li>Images of child abuse and sexual depiction of minors</li>
          <li>Images of human or animal abuse.</li>
          <li>Access to betting or gambling products or services</li>
        </ul>
        <br />
        <br />
        <strong>Drugs, medication, and supplements</strong>
        <br />
        <br />
        Sometimes people want to share their experience with struggles they
        faced and how they got better as a way of helping others who are
        struggling, and the same is acceptable. However, we do not want creators
        to encourage harmful or dangerous behaviors. As a result, you cannot
        offer the following as a benefit:
        <ul className="list-disc pl-5 text-left  mt-0 mb-2.5">
          <li>Drugs or drug paraphernalia.</li>
          <li>
            Partial payment for drugs, medication or supplements such as
            coupons, gift cards, or any incentive to purchase or obtain the
            categories mentioned above.
          </li>
          <li>
            Medication or food supplements such as weight loss, energy boost or
            miracle cures.
          </li>
          <li>
            Books or any other form of educational material claiming that they
            can help cure disease.
          </li>
        </ul>
        Note that Blaze allows creations engaging or discussing the general
        health and fitness i.e Yoga, Fitness, Sports, Games, Body-building etc.
        Any claims about the efficacy of treatments or supplements must comply
        with FDA regulations. False or misleading health claims are strictly
        prohibited.
        <br />
        <br />
        <strong>Weapons and explosives</strong>
        <br />
        <br />
        You cannot offer the following as benefits on Blaze:
        <ul className="list-disc pl-5 text-left mt-0 mb-2.5">
          <li>Weapons of any kind including lethal weapons</li>
          <li>fireworks</li>
          <li>
            Partial payment such as coupons, gift cards, or any incentive to
            purchase or obtain firearms or explosives.
          </li>
          <li>
            Tutorials, instructions, or demonstrations for crafting or modifying
            weapons, such as 3D printed designs, manufacturing firearms, and
            other DIY creations
          </li>
        </ul>
        <br />
        <br />
        <strong>Alcohol and smoking</strong>
        <br />
        <br />
        You cannot offer the following:
        <ul className="list-disc pl-5 text-left mt-0 mb-2.5">
          <li>
            Alcohol and smoking substances such as tobacco, cigars, vapes,
            e-cigarette, rolling paper, and cigarettes.
          </li>
          <li>
            Promotions, coupons, or any other incentives to purchase or obtain
            alcohol and smoking products
          </li>
          <li>Kits to brew alcohol.</li>
        </ul>
        <br />
        <br />
        <strong>Finance and Cryptocurrency</strong>
        <br />
        <br />
        You are free to talk about and share creations around financial advice
        and cryptocurrency, but Blaze was not designed to launch an ICO or
        create an investment scheme. Therefore, while you can share creations
        and advice, you cannot offer the following benefits:
        <ul className="list-disc pl-5 text-left mt-0 mb-2.5">
          <li>
            Coins as part of a benefit or making your subscribers an investor.
          </li>
          <li>
            Partial payment such as coupons, gift cards, or any incentive to
            purchase or obtain cryptocurrency.
          </li>
          <li>Pyramid scheme, Ponzi scheme, or other fraudulent scheme.</li>
          <li>
            You cannot offer benefits that promise subscribers a financial
            benefit based on following advice provided in the benefit such as
            "if you pay 5000/- a month, my advice will earn you 10 times that."
          </li>
          <li>
            Blaze does not facilitate investment advice or financial
            transactions beyond content sharing, and all content must comply
            with SEC regulations regarding financial advice and cryptocurrency.
          </li>
        </ul>
        <br />
        <br />
        <strong>Sex work and sexual services</strong>
        <br />
        <br />
        We prohibit pornography or sexual services to be funded on Blaze. As a
        result, you cannot offer the following benefits:
        <ul className="list-disc pl-5 text-left mt-0 mb-2.5">
          <li>
            You cannot use Blaze to provide access to pornographic material such
            as giving access to a website or shared private access to
            pornographic creations whether on Blaze or on any other platform.
            Pornography is defined as real people engaging in sexual acts such
            as masturbation or sexual intercourse on camera.
          </li>
          <li>
            You cannot offer items that are either sexually gratifying or
            intimate in nature.
          </li>
          <li>
            You cannot use Blaze’s payment system to fund escort activity.
          </li>
          <li>
            You cannot offer an erotic webcam session to your subscribers. This
            means that we have a no nudity policy for creators.
          </li>
        </ul>
        <br />
        <br />
        <strong>Health, hygiene and consumables</strong>
        <br />
        <br />
        Creations related to cooking, how to make your own products and
        discussing health are allowed, but creators cannot offer ingredients or
        consumables as a benefit.
        <br />
        <br />
        Creators must not offer or promote the sale of ingestible products
        without proper FDA approval. Discussions about health and hygiene are
        permitted within the boundaries of legal compliance.
        <br />
        <br />
        <strong>Multi-level marketing and direct selling</strong>
        <br />
        <br />
        Blaze is not a platform to launch a direct sales business or facilitate
        MLMs. Blaze is not meant to be a means to promote a direct sales
        business, but a place for creators to launch creative endeavors.
        Products manufactured and sold by direct marketing companies are not a
        benefit to be offered on Blaze.
        <br />
        <br />
        Blaze prohibits the promotion of any business models that are identified
        as pyramid schemes under U.S. law. All business promotions must comply
        with the FTC’s guidelines on endorsements and advertising.
        <br />
        <br />
        <strong>Raffles</strong>
        <br />
        <br />
        You cannot request payment for raffles or giveaways as part of a
        benefit. In other words, subscribers should not have to pay for the
        chance to win something. This includes lotteries, raffles or even
        mystery boxes if the value of the box is not guaranteed. If you want to
        offer any form of giveaways to your followers, make sure to open them to
        your fan base in general, ensure they are not only accessible to your
        subscribers, and follow the applicable laws.
        <br />
        <br />
        <strong>Other things to note</strong>
        <br />
        <br />
        These guidelines are governed by the laws of the State of Delaware, USA,
        and any disputes will be subject to the exclusive jurisdiction of the
        courts of Wilmington, Delaware. Blaze reserves the right to review and
        remove any content that violates these guidelines or is otherwise
        harmful to the community, without prior notice.
        <br />
        <br />
        The terms and conditions herein are subject to changes by Blaze without
        any prior notification. You hereby agree that Blaze has the exclusive,
        irrevocable and absolute rights to amend the Terms &amp; Conditions.
        <br />
        <br />
        This list is not exhaustive and might change in the future as
        technologies evolve and life simply happens. If you have doubts about
        your projects, please contact our team at <a className="text-blue-500" target="_blank">support@blazelive.app</a>. We are
        always happy to help.
        <br />
        <br />
        This policy is part of Blaze’s Terms of Use and Platform Guidelines. For
        more information, contact Blaze at <a className="text-blue-500" target="_blank" href="">support@blazelive.app</a>
        <br />
        <br />
        <p></p>
      </div>
    </div>
  );
}

export default ContentGuidelines;
