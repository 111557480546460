import React from "react";

function PlatformGuidelines() {
  return (
    <div className="p-6 min-h-screen text-white">
      <div className="text-center text-3xl font-bold px-[20%]">
        <br />
        <span className="text-white text-center mb-4">
          Platform Guidelines
        </span>
      </div>
      <br />
      <br />

      <div className=" px-[20%] text-base">
        <p>
          <strong>How these guidelines are enforced</strong>
          <br />
          <br />
          Please realize that Blaze is a diverse community and, while you may
          not necessarily agree with someone’s point of view, it may not be a
          violation of our Platform Guidelines. That said, when you see a page
          on Blaze that you feel violates our Platform Guidelines, please take
          the time to report them via our reporting tool. A member of the team
          will review the report and if our Platform Guidelines have been
          violated, the case manager will contact the creator to let them know
          that they are outside these guidelines.
          <br />
          <br />
          This is a commitment to creators. We know this is your livelihood. Our
          first reflex is always to try to educate creators to help them
          understand how a rule has been broken and how to remedy the issue. In
          cases where the violation is particularly bad or intentional we may
          take further action such as suspending or removing their account. In
          the most extreme cases we may ban a creator from using Blaze.
          <br />
          <br />
          Blaze is for creators who put something original out into the world.
          As a creator, you may not post creations that infringe on others'
          intellectual property rights, respecting intellectual property laws,
          including the Digital Millennium Copyright Act (DMCA), and providing
          mechanisms for copyright holders to report infringements.
        </p>
        <ul className="list-disc">
          <li>
            You cannot create fake pages or collect money for things, products
            you’re not actually delivering. For example, you cannot create a fan
            page for someone else.
          </li>
          <li>
            You cannot pretend to be or impersonate someone else by using the
            name, brand or works of another to deceive subscribers. We will
            review satire and comedy in a different way as we believe these
            subgenres are original creations.
          </li>
        </ul>
        <br />
        <br />
        <strong>Bullying, harassment, and threats</strong>
        <br />
        <br />
        <p>
          We do not allow bullying or harassment because we want Blaze to be a
          safe place for our various communities. At the same time, we want
          people to be able to express themselves, be critical, and discuss
          controversial issues. Blaze employs automated and manual review
          processes to detect and address bullying and harassment. Repeated
          offenders may face permanent account suspension.
        </p>
        <br />
        <br />
        <strong>Bullying</strong>
        <br />
        <br />
        <p>
          You cannot attempt to intimidate anyone, either directly or by using
          your influence over others. We treat real-life interactions and online
          interactions with equal seriousness when analyzing whether a line has
          been crossed and take appropriate steps to arrest the incident
          immediately.
        </p>
        <br />
        <br />
        <strong>Harassment</strong>
        <br />
        <br />
        <p>
          Anyone on Blaze should be able to express their opinion in a way that
          doesn't threaten or cause any distress, pain to another person. In
          this respect, we take threats of violence very seriously. Any creator
          or subscriber threatening the well-being of an individual or group of
          people will be removed from the platform immediately without any
          notice. This includes threatening behavior such as stalking or
          inciting others to commit violent acts.
        </p>
        <br />
        <p>
          If you are a victim of this behavior and feel that your personal
          safety is at risk, we suggest you contact your local law enforcement,
          in addition to reporting the behavior to us.
        </p>
        <br />
        <br />
        <strong>Hate speech</strong>
        <br />
        <br />
        There is no room on Blaze for projects funding hate speech, such as
        calling for violence, exclusion, or segregation. This includes serious
        attacks on people based on their race, ethnicity, national origin,
        religion, sex, gender, sexual orientation, age, disability or serious
        medical conditions.
        <br />
        <br />
        <strong>18+ creations</strong>
        <br />
        <br />
        As we strive to create a community that is inclusive and diverse, we
        want to take into consideration our diverse user base of different ages
        and sensitivities. We require that all posts on your page be appropriate
        for all audiences/ages. Posts with mature themes must be marked as
        “Subscriber Only". Blaze will use a combination of automated tools and
        human review to detect and remove such content. Repeated offenses will
        result in permanent account bans.
        <br />
        <br />
        <strong>Nudity</strong>
        <br />
        <br />
        You cannot post nude creations on Blaze of both real and fictional
        subjects. This means that you cannot post nudity in public areas
        including your profile picture, banner, reward images or ‘about me’
        section. As a reminder, you should never post or share nude creations of
        any individual under the age of 18, including yourself.
        <br />
        <br />
        <strong>Pornography and sexual services:</strong>
        <br />
        <br />
        We do not allow pornographic material or sexual services on Blaze, which
        we define as "real people engaging in sexual acts such as masturbation
        or sexual intercourse on camera." For further information, please
        consult our Content & Creation Guidelines.
        <br />
        <br />
        <strong>Offensive and graphic creations:</strong>
        <br />
        <br />
        We review any content reported as offensive and graphic & we have zero
        tolerance when it comes to the glorification of sexual violence which
        includes bestiality, rape, and child exploitation (i.e., sexualized
        depiction of minors). This is true for illustrated, animated, or any
        other type of creations. Blaze reserves the right to review and remove
        accounts that may violate this guideline.
        <br />
        We also do not allow other fringe sexual fetish creations, such as
        incest, necrophilia, or fetish creations.
        <br />
        <br />
        <strong>People who cannot use Renown</strong>
        <br />
        <br />
        Because Blaze empowers people financially, we restrict both the types of
        media and projects that can be funded on Blaze, and also which people
        can and cannot receive funds through Blaze. After creating a Blaze
        account, any creator caught in the act or convicted of making credible
        violent threats, committing violent crimes, malicious doxing,
        coordinating nonviolent harm (such as fraud, money laundering and
        gambling), or encouraging others to do any of these activities may be
        banned from using Blaze.
        <br />
        <br />
        <strong>Politicians:</strong>
        <br />
        <br />
        You cannot use Blaze to fund your run for office or to fund for
        political campaigns.
        <br />
        <br />
        <strong>Criminal or Harmful Past:</strong>
        <br />
        <br />
        Any creator caught in the act or convicted of making credible violent
        threats, committing violent crimes, child abuse, malicious doxing,
        coordinating nonviolent harm (such as fraud, money laundering, and
        gambling), or encouraging others to do any of these activities, may be
        banned from using Blaze.
        <br />
        <br />
        <strong>Dangerous Organizations:</strong>
        <br />
        <br />
        Any individual or group with a criminal history or a suspect or having
        any affiliation with violent or dangerous groups (including terrorist or
        cyber terrorist organizations, organized criminal groups, and violent
        hate groups), cannot receive funds through Blaze, no matter the purpose
        or apparent intention of their Blaze account.
        <br />
        Any creator praising or actively supporting these groups or their
        leaders will not be allowed to use Blaze.
        <br />
        <br />
        <strong>Harmful and illegal activities</strong>
        <br />
        <br />
        We do not allow funds to be collected for any harmful or illegal
        activities. You cannot fund creations that advocate, threaten, or show
        you causing harm to yourself, other people or any other living being or
        causing damage, harm to property etc. In the same way, you cannot
        promote illegal activities such as property crime, distribution of
        illegal weapons, or drug manufacturing etc.
        <br />
        <br />
        <strong>Self-harm:</strong>
        <br />
        <br />
        We do not allow any page that promotes or glorifies self-harm,
        self-injury, suicide, physical challenges or an eating disorder.
        <br />
        <br />
        If you or someone close to you might be in danger of hurting themselves,
        please contact local authorities or seek help.
        <br />
        <br />
        <strong>Illegal activity:</strong>
        <br />
        <br />
        We do not allow accounts that collect money for any illegal purpose, or
        that encourage others to break the law. For example, creators should not
        promote illegal weapons, drug manufacturing techniques or distribution,
        or property crime. For example, we do not allow creations that help
        organize or encourage vandalism. Note that we do allow street art.
        <br />
        <br />
        <strong>Dangerous activities:</strong>
        <br />
        <br />
        We ask those creators who are posting dangerous stunts to include a
        clear disclaimer. We also require that this types of posts be marked as
        “Subscriber Only" if it is too graphic or dangerous for a general
        audience.
        <br />
        <br />
        <strong>Spam</strong>
        <br />
        <br />
        While it can be hard to grow a subscriber base, no one likes being
        spammed. Be creative and original, don’t post repetitive comments or
        send unwanted private messages to other members of the community.
        <br />
        These are some of the actions we consider to be spamming, which is
        prohibited on Renown:
        <br />
        <br />
        <ul style={{ listStyle: "disc" }}>
          <li>
            Don’t create pledging schemes; don’t contact other creators to have
            a pledge-for-a-pledge kind of trade deal.
          </li>
          <li>
            Don’t use Blaze to release or link to malware or phishing schemes
          </li>
          <li>Don’t make misleading posts to grow your subscriberage.</li>
          <li>
            Don’t post comments on other creators’ pages promoting your own
            page.
          </li>
          <li>
            Don’t send a large number of unsolicited private messages asking for
            support.
          </li>
          <li>
            Don't use incorrect tagging in order to drive more search results.
          </li>
          <li>
            Don’t post multiple paid posts per day if you are a per-post
            creator.
          </li>
        </ul>
        When reviewing reports about spam, we will distinguish between
        commercial spam and friendly abuse such as legitimate creators spamming
        other creators’ accounts. If we determine the spam is friendly abuse,
        the account will be flagged; repeat offenses will lead to full removal
        of the creator or subscriber page. Commercial spamming by an account
        that is not a legitimate creator will be removed from Blaze.
        <br />
        <br />
        <strong>Subscriber or Fan accounts</strong>
        <br />
        <br />
        The philosophy behind these guidelines also applies to subscribers.
        While we believe that creators are best at managing their communities
        there are some instances when our team will review reports for
        subscribers' behavior.
        <br />
        <br />
        If you would like us to review the behavior of one of your subscribers
        we will keep the review confidential.Our team reviews reports around
        hate speech, harassment and solicitation very carefully and we will take
        action against a subscriber account if we find that they are in
        violation of these guidelines.
        <br />
        <br />
        ​​Users can report violations of these guidelines through the in-app
        reporting tool or by contacting support directly. Blaze will investigate
        all reports and take appropriate action, including content removal,
        account suspension, or notifying law enforcement as necessary.
        <br />
        <br />
        Please remember that we will not share any details related to what kind
        of action we might have taken against a subscriber's account. Most
        importantly, if you feel unsafe because a subscriber is stalking or
        harassing you, you should always try to seek the help of your local law
        enforcement and contact the cyber crime unit. Blaze will work closely
        with law enforcement whenever there is an investigation.
        <br />
        <br />
        This policy is part of Blaze’s Terms of Use. For further information,
        contact Blaze at <a href="" target="_blank" className="text-blue-500">support@blazelive.app</a>. Blaze’s role
        <br />
        <br />
        <strong>Blaze’s role</strong>
        <br />
        <br />
        At Blaze our endeavour is to create an environment where an individual
        creator/business can earn and focus on their art. We will do our best in
        making sure the creators get the opportunity to monetize their art and
        fan following. We aspire to empower the creators so that they can become
        their own BOSS. Said that we clearly understand and also propagate the
        fact that we all need to work in the realms of law.
        <br />
        <br />
        We function as a platform connecting creators with their fanbase to help
        them earn from their creations.Blaze operates under the jurisdiction of
        U.S. federal and state laws, including but not limited to the
        Communications Decency Act (CDA), the Children’s Online Privacy
        Protection Act (COPPA), and the Digital Millennium Copyright Act (DMCA).
        All content and activities on Blaze must comply with these laws. Here
        are the terms that we intend to follow while operating our platform
        which does not allow any creator on Blaze to post the content which :
        <br />
        <br />
        <ul style={{ listStyle: "disc" }}>
          <li>
            belongs to another person and to which the user does not have any
            right to;{" "}
          </li>
          <li>
            is grossly harmful, harassing, blasphemous, defamatory, obscene,
            pornographic, pedophilic, libelous, invasive of another's privacy,
            hateful, or racially, ethnically objectionable, disparaging,
            relating or encouraging money laundering or gambling, or otherwise
            unlawful in any manner whatever;
          </li>
          <li>harm minors in any way;</li>
          <li>
            infringes any patent, trademark, copyright or other proprietary
            rights;
          </li>
          <li>violates any law for the time being in force;</li>
          <li>
            deceives or misleads the addressee about the origin of such messages
            or communicates any information which is grossly offensive or
            menacing in nature;
          </li>
          <li>impersonates another person;</li>
          <li>
            contains software viruses or any other computer code, files or
            programs designed to interrupt, destroy or limit the functionality
            of any computer resource;
          </li>
          <li>
            threatens the unity, integrity, defence, security or sovereignty of
            India, friendly relations with foreign states, or public order, or
            causes incitement to the commission of any cognisable offence or
            prevents investigation of any offence or is insulting any other
            nation.{" "}
          </li>
          <li>
            threatens public health or safety; promotion of cigarettes or any
            other tobacco products or consumption of intoxicant including
            alcohol and Electronic Nicotine Delivery System (ENDS) &amp; like
            products that enable nicotine delivery except for the purpose &amp;
            in the manner and to the extent, as may be approved under the Drugs
            and Cosmetics Act, 1940 and Rules made thereunder;{" "}
          </li>
          <li>threatens critical information infrastructure</li>
        </ul>
        <p></p>
      </div>
    </div>
  );
}
export default PlatformGuidelines;
