import React from "react";
import images from "../../Constants/images";

function RechargeCoin({ coins, rupee, productId, onClick }) {
  return (
    <div
      className="w-28 h-28 sm:h-36 sm:w-36 md:h-44 md:w-44 lg:h-64 lg:w-64 bg-gray-700 rounded-2xl flex flex-col justify-between items-center"
      onClick={() => onClick(productId)}
    >
      {/* Top Section: Coin Image */}
      <div className="flex-shrink-0 mt-2 md:mt-4">
        <img
          src={images.CoinsImg}
          className="w-10 h-10 sm:w-16 sm:h-16 md:w-20 md:h-20 lg:w-28 lg:h-28 object-contain"
          alt="Coins"
        />
      </div>

      {/* Middle Section: Coin Quantity */}
      <div className="flex flex-grow flex-col items-center justify-center my-2">
        <div className="flex items-center justify-center">
          <img
            src={images.MyBalanceCoinIcon}
            className="w-4 h-4 sm:w-5 sm:h-5 md:w-6 md:h-6 lg:w-7 lg:h-7 mr-1"
            alt="Balance Icon"
          />
          <p className="coins-text text-xs sm:text-sm md:text-base lg:text-lg font-bold">
            {coins}
          </p>
        </div>
      </div>

      {/* Bottom Section: Price Button */}
      <div className="w-28 sm:w-36 md:w-44 lg:w-64">
        <button className="w-full text-xs sm:text-sm md:text-base lg:text-lg bg-blue-600 text-white rounded-lg py-1">
          ₹{rupee}
        </button>
      </div>
    </div>
  );
}

export default RechargeCoin;
