import React, { useContext, useState } from "react";
import "./login.css";
import images from "../../Constants/images";
import Button from "../Buttons/Button";
import { AuthUserContext } from "../Context/AuthUserContext"; // Adjust path as needed
import { useNavigate } from "react-router-dom";
import { loginUser,downloadFromStore } from "../../apiUtils"; // Import the loginUser function
import AppStoreLinks from "../AppStoreLinks";

const Login = () => {
  const { setUser, setIsAuthenticated,user } = useContext(AuthUserContext);
  const [username, setUsername] = useState(""); // Renamed user to username for clarity
  const [mobile, setMobile] = useState("");
  const [isOtpSent, setIsOtpSent] = useState(false);
  const navigate = useNavigate();
  

  // Function to handle changes in username input field
  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

const handleLogin = async () => {
  try {
    const loginResponse = await loginUser(username); // username can be either username or mobile
    console.log("Its login response",loginResponse)

    if (loginResponse.message === "OTP sent") {
      setIsOtpSent(true);

      const maskedMobile = loginResponse.mobile; // Store only the masked mobile number
      const verifiedIdentifier = loginResponse.username || username; // Store the identifier returned by the API or the input
      
      setUser({ identifier: verifiedIdentifier }); // Store the identifier used (username or mobile)

      // Pass both the mobile and the identifier in the same state object
      navigate("/LoginOTP", { state: { mobile: maskedMobile, identifier: verifiedIdentifier } });

    } else {
      console.error("Unexpected response:", loginResponse.message);
    }
  } catch (error) {
    console.error("Login error:", error);
  }
};

  return (
    <div className="">
      <div className="flex justify-center items-center md:px-10 px-4">
        <div
          className="text-white flex flex-col items-center justify-between gap-5 md:items-start"
          style={{ flex: 0.4 }}
        >
          <div className="text-center md:text-left">
            <p className="signup-heading1 text-3xl md:text-6xl lg:text-7xl xl:text-8xl font-bold">
              Stream Live
            </p>
            <p className="signup-heading2 text-3xl md:text-6xl lg:text-7xl xl:text-8xl font-bold">
              Feel Alive
            </p>
          </div>

          <p className="text-lg md:text-xl text-center md:text-left">
            Login to your account
          </p>
          <div className="flex flex-col sm:w-full md:w-3/4 lg:w-2/3 xl:w-1/2">
            <input
              className="input-signup text-base px-4 py-3 rounded-md bg-gray-300"
              type="text"
              placeholder="Mobile/Username"
              value={username} // Set value from state
              onChange={handleUsernameChange} // Update state on change
            />

            <button
              className="signup-continue text-base md:text-lg font-bold mt-4 w-full px-4 py-3 rounded-md text-white"
              style={{
                background:
                  "linear-gradient(90deg, rgba(100, 46, 255, 1) 0%, rgba(213, 46, 255, 1) 100%)",
              }}
              onClick={handleLogin}
            >
              Continue
            </button>
          </div>
          <p className="text-base md:text-lg text-center md:text-left">
            Don't have an account?
            <span><a style={{color:'rgba(255, 61, 178, 1)',cursor:'pointer'}} onClick={downloadFromStore}>{" "}Download BLAZE</a></span>
          </p>
          <div className="text-base md:text-lg text-center md:text-left mt-4">
            <p>By continuing, you agree to our</p>
            <p>Terms and Conditions, Privacy Policies, and Content Policies</p>
          </div>

          <div className="md:hidden sm:flex flex-col">
            <p
              className="text-center md:text-left text-base md:text-lg py-2"
              style={{ color: "#FFFFFF" }}
            >
              Download the App
            </p>
            <AppStoreLinks/>
          </div>
        </div>

        {/* Circles Container */}
        <div
          className="hidden md:flex flex-col justify-center items-center rounded-l-2xl py-10"
          style={{ flex: 0.4, height: "100%", minHeight: "100%" }}
        >
          <div>
            <img src={images.BlazeLandingMobile} alt="Blaze Main Screen" />
          </div>
          <div className="md:flex flex-col justify-center">
            <p
              className="text-center text-base md:text-lg py-2"
              style={{ color: "#FFFFFF" }}
            >
              Download the App
            </p>
            <AppStoreLinks/>

          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
