import React from "react";
import images from "../../Constants/images";
import { Link } from "react-router-dom";
import { useContext } from "react";
import { AuthUserContext } from "../Context/AuthUserContext";
import { useNavigate } from "react-router-dom";

const FooterScreen = () => {
  const navigate = useNavigate();
  const { isAuthenticated } = useContext(AuthUserContext);

  const handleLogoClick = () => {
    if (isAuthenticated) {
      navigate("/Home");
    } else {
      navigate("/");
    }
  };

  return (
    <div className=" text-white py-10 text-base md:text-lg">
      {/* Mobile view layout */}
      {/* Links */}
      <div className="flex flex-col sm:hidden text-white space-y-2 sm:space-y-0">
          <div className="flex flex-col items-center sm:items-start space-y-2">
            <Link to="/AboutUs">
              <p>About Us</p>
            </Link>
            <Link to="/ContactUs">
              <p>Contact Us</p>
            </Link>
            <Link to="/TermsOfUse">
              <p>Terms of Use</p>
            </Link>
          </div>
          <div className="flex flex-col items-center sm:items-start space-y-2">
            <Link to="/PrivacyPolicy">
              <p>Privacy Policy</p>
            </Link>
            <Link to="/PlatformGuidelines">
              <p>Platform Guidelines</p>
            </Link>
            <Link to="/ContentGuidelines">
              <p>Content & Creation Guidelines</p>
            </Link>
          </div>
        </div>

      {/* Tablet and PC view layout */}
      <div className="hidden sm:flex sm:flex-col sm:items-center text-white py-4">
        <div className="flex flex-col sm:flex-row sm:justify-between w-full px-8">
          {/* Column 1 */}
          <div className="flex flex-col items-center sm:items-start mb-4 sm:mb-0">
            <img
              src={images.BlazeIconLogo}
              alt="Blaze Logo"
              className="h-8 w-auto sm:h-10 md:h-12 lg:h-16 cursor-pointer"
              onClick={handleLogoClick}
            />
            <p className=" font-normal mt-2">
              © 2024 Blaze. All rights reserved.
            </p>
          </div>

          {/* Column 2 */}
          <div className="flex flex-col sm:mr-8  space-y-2">
            <Link to="/AboutUs">About Us</Link>
            <Link to="/ContactUs">Contact Us</Link>
            <Link to="/TermsOfUse">Terms of Use</Link>
          </div>

          {/* Column 3 */}
          <div className="flex flex-col  space-y-2">
            <Link to="/PrivacyPolicy">Privacy Policy</Link>
            <Link to="/PlatformGuidelines">Platform Guidelines</Link>
            <Link to="/ContentGuidelines">Content & Creation Guidelines</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FooterScreen;
