import React from "react";
import "../BlazeS5/blazescreen5.css";
// import Slider2Landing1 from "../Carausel2Landing/Slider2Landing1";
import Slider2Landing2Style2 from "../Carausel2Landing/Slider2Landing2Style2";

const BlazeScreen5 = () => {
  return (
    <div>
      <p className="screen5-heading text-lg md:text-2xl lg:text-3xl text-center font-black">
        Happy Users
      </p>
      <p className="screen5-para text-white text-center text-base md:text-lg lg:text-xl px-8 pt-2 pb-8">
        Stream like a pro with our feature-packed live streaming platform
        designed for creators
      </p>

      {/* <div> */}
        <Slider2Landing2Style2 />
      {/* </div> */}
    </div>
  );
};

export default BlazeScreen5;
