import React from "react";
import "../AboutUs/aboutus.css";
import images from "../../Constants/images";

const AboutUs = () => {
  return (
    <div className="md:mx-auto px-2 sm:px-10 md:px-15 lg:px-30">
      <p className="text-center text-2xl md:text-4xl lg:text-6xl font-black pt-10 text-transparent bg-clip-text bg-gradient-to-r from-[#ffc266] via-[#ff659a] to-[#ff1e78]">
        About Us
      </p>

      <div className="object-contain pt-10 w-11/12 mx-auto">
        <img src={images.AboutUsImg} className={"md:hidden"} />
        {/* section1 only large image for Responsive */}
        <div className="AboutUsLarge hidden md:w-full md:flex md:items-center md:justify-center">
          <img
            src={images.AboutUsImgLarge}
            className={"hidden md:flex md:object-contain"}
          />
        </div>
      </div>

      <div className="flex mt-20 flex-col items-center gap-8 text-white text-base font-medium text-center px-5">
        {/*section2 Bold paragraph below About us image Responsive */}
        <div className="text-base md:text-lg">
          <p>
            At Blaze, we're passionate about empowering creators to share their
            content with the world. Our intuitive live streaming platform is
            designed to help you connect with your audience like never before,
            and build meaningful relationships with your followers in real-time.
          </p>
        </div>
        {/* section3 small size flex of 2 div paragraphs Responsive */}
        <div className="md:flex md:justify-between gap-5 mt-4">
          <p className="text-base">
            We believe that live streaming is the future of content creation,
            and we're committed to providing a platform that's accessible,
            easy-to-use, and packed with powerful features. Whether you're a
            seasoned creator or just starting out, our app has everything you
            need to take your content to the next level.
          </p>
          <p className="text-base ">
            Our team is made up of experienced developers, designers, and
            content creators who are dedicated to building a product that meets
            the needs of our users. We're constantly updating and improving our
            app to ensure that you have the best possible experience when
            streaming live.
          </p>
        </div>
        {/* section4 small size para last one of above section Responsive */}
        <div>
          <p className="text-base  md:text-lg ">
            We're excited to be a part of the live streaming revolution, and
            we're committed to supporting our community of creators every step
            of the way. Join us today and start sharing your passion with the
            world!
          </p>
        </div>
      </div>
      <div className="flex flex-col md:flex md:flex-row-reverse ">
        <p className="Mission text-xl font-black text-center py-16 md:hidden">
          Our Misson
        </p>

        <div className="h-72 w-72 m-auto md:hidden">
          <img src={images.OurMissonImg} />
        </div>

        <div className="hidden md:flex flex-row-reverse md:w-full gap-40 pt-20 justify-center items-center">
          <div>
            <img src={images.OurMissionImgLarge} width={2000} />
          </div>

          <div className="flex flex-col text-left">
            <p className="Mission hidden md:flex MissionResponsive md:text-xl md:font-black">
              Our Misson
            </p>

            <p className="text-center text-base md:text-left md:px-0 text-white pt-10">
              Our mission at Blaze is to empower creators to share their content
              with the world through the power of live streaming. We're
              committed to providing a platform that's accessible, easy-to-use,
              and packed with powerful features that enable our users to build
              meaningful connections with their audience in real-time.
            </p>
          </div>
        </div>
      </div>

      <p className="Vision pt-12 text-xl font-black text-center">Our Vision</p>
      <div className="horizontal-Vision-section md:w-full md:flex md:justify-center gap-4">
        {/* 1#creative section */}

        <div className="flex flex-col mt-5">
          <div className="object-contain pt-10 md:py-0 m-auto">
            <img src={images.CreativityIdea} className={"w-36"} />
          </div>
          <p className="text-base font-bold text-white text-center pt-5 md:text-xl">
            Creativity
          </p>
          <p className="text-white text-base text-center mt-8 ">
            We believe that creativity is essential to human expression, and
            we're committed to providing a platform that empowers creators to
            unleash their full creative potential.
          </p>
        </div>

        {/* 2#Accessablity section */}
        <div className="flex flex-col mt-5">
          <div className="object-contain pt-10 md:py-0 m-auto">
            <img src={images.Accesseblity} className={"w-36"} />
          </div>
          <p className="text-base font-bold text-white text-center pt-5 md:text-xl">
            Accessablity
          </p>
          <p className="text-white text-base text-center mt-8">
            We believe that everyone should have access to the tools and
            resources they need to share their content with the world,
            regardless of their background or level of experience.
          </p>
        </div>
        {/* 3#Community section */}
        <div className="flex flex-col mt-5">
          <div className="object-contain pt-10 md:py-0 m-auto">
            <img src={images.Community} className={"w-36"} />
          </div>
          <p className="text-base font-bold text-white text-center pt-5 md:text-xl">
            Community
          </p>
          <p className="text-white text-base text-center mt-8">
            We believe that building a strong community of creators is essential
            to the success of our platform, and we're committed to fostering an
            environment that's supportive, inclusive, and collaborative.
          </p>
        </div>
        {/* 4#Innovation section */}
        <div className="flex flex-col mt-5">
          <div className="object-contain pt-10 md:py-0 m-auto">
            <img src={images.Innovation} className={"w-36"} />
          </div>
          <p className="text-base font-bold text-white text-center pt-5 md:text-xl">
            Innovation
          </p>
          <p className="text-white text-base text-center mt-8">
            We believe that live streaming is the future of content creation,
            and we're committed to pushing the boundaries of what's possible
            with our innovative technology and features
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
