import Slider from "react-slick";
import "../../../node_modules/slick-carousel/slick/slick.css";
import "../../../node_modules/slick-carousel/slick/slick-theme.css";
import React, { Component } from "react";
import Card2Style2 from "./Card2Style2";
import "../Carausel2Landing/slider2landing2style.css";

export default class Slider2Landing2Style2 extends Component {
  render() {
    const settings = {
      customPaging: function (i) {
        return (
          <div
            className="md:hidden"
            style={{
              height: 10,
              width: 10,
              marginTop: 30,
              borderRadius: 50,
              backgroundColor: "white",
            }}
          ></div>
        );
      },
      infinite: true,
      speed: 500,
      slidesToShow: 2,
      slidesToScroll: 1,
      arrows: false,
      dots: false,
      arrows: true,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1,
            infinite: true,
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1,
            infinite: true,
            arrows: true,
          },
        },
      ],
    };
    return (
      <div className="px-12 text-xs">
        <Slider {...settings}>
          <div className="flex-1">
            <Card2Style2
              paragraph="The team is amazing to work with. The Brandkart team guides us with customised requirements and also recommends most suitable influencers for the campaigns. I would recommend this platform to those who are looking to work with a hassle-free team.
"
              writer="Sachin Mittal"
              place="New Delhi"
            />
          </div>
          <div className="flex-1">
            <Card2Style2
              paragraph="The team is amazing to work with. The Brandkart team guides us with customised requirements and also recommends most suitable influencers for the campaigns. I would recommend this platform to those who are looking to work with a hassle-free team.
"
              writer="Sachin Mittal"
              place="New Delhi"
            />
          </div>
          <div className="flex-1">
            <Card2Style2
              paragraph="The team is amazing to work with. The Brandkart team guides us with customised requirements and also recommends most suitable influencers for the campaigns. I would recommend this platform to those who are looking to work with a hassle-free team.
"
              writer="Sachin Mittal"
              place="New Delhi"
            />
          </div>
          <div className="flex-1">
            <Card2Style2
              paragraph="The team is amazing to work with. The Brandkart team guides us with customised requirements and also recommends most suitable influencers for the campaigns. I would recommend this platform to those who are looking to work with a hassle-free team.
"
              writer="Sachin Mittal"
              place="New Delhi"
            />
          </div>
        </Slider>
      </div>
    );
  }
}
