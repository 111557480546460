import React, { useContext } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { AuthUserContext } from "./Components/Context/AuthUserContext";
import Navbar from "./Components/Navbar/Navbar";
import FooterScreen from "./Components/Footer/FooterScreen";
import HomePage from "./Components/HomePage/HomePage";
import AboutUs from "./Components/AboutUs/AboutUs";
import Community from "./Components/Commuity/Community";
import Blogs from "./Components/Blogs/Blogs";
import Recharge from "./Components/Recharge/Recharge";
import Login from "./Components/WebSignUp/Login.jsx";
import Home from "./Components/LandingPage/Home";
import PromotionalRecharge from "./Components/PromotionalRecharge";
import PaymentGateway from "./Components/PaymentGateway";
import LoginOTP from "./Components/WebSignUp/LoginOTP.jsx";
import SignUpPage from "./Components/SignIn/SignUpPage";
import SignUpOTP from "./Components/SignIn/SignUpOTP";
import FAQs from "./Components/FAQs/FAQs.jsx";
import PrivacyPolicy from "./Components/Footer/PrivacyPolicy.jsx";
import TermsOfUse from "./Components/Footer/TermsOfUse.jsx";
import PlatformGuidelines from "./Components/Footer/PlatformGuidelines.jsx";
import StripeCallback from "./Components/StripeCallback.jsx";
import ContentGuidelines from "./Components/Footer/ContentGuidelines.jsx";
import "./App.css";
import FAQ from "./Components/FAQs/FAQ.jsx";
import ProtectedRoute from "./Components/ProtectedRoute"; 
import ContactUs from "./Components/Footer/ContactUs.jsx";

function App() {
  const { isAuthenticated } = useContext(AuthUserContext);

  return (
    <div className="app-container">
      <div className="app-header">
        <Navbar />
      </div>
      <div className="app-content">
        <Routes>
          {/* Public Routes */}
          <Route path="/" element={<HomePage />} />
          <Route path="/Login" element={<Login />} />
          <Route path="/LoginOTP" element={<LoginOTP />} />
          <Route path="/FAQ" element={<FAQ />} />
          <Route path="/AboutUs" element={<AboutUs />} />
          <Route path="/ContactUs" element={<ContactUs />} />
          <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
          <Route path="/TermsOfUse" element={<TermsOfUse />} />
          <Route path="/PlatformGuidelines" element={<PlatformGuidelines />} />
          <Route path="/ContentGuidelines" element={<ContentGuidelines />} />

          {/* Protected Routes */}
          <Route
            path="/Home"
            element={
              <ProtectedRoute isAuthenticated={isAuthenticated}>
                <Home />
              </ProtectedRoute>
            }
          />
          <Route
            path="/PromotionalRecharge"
            element={
              <ProtectedRoute isAuthenticated={isAuthenticated}>
                <PromotionalRecharge />
              </ProtectedRoute>
            }
          />
          <Route
            path="/stripe/callback"
            element={
              <ProtectedRoute isAuthenticated={isAuthenticated}>
                <StripeCallback />
              </ProtectedRoute>
            }
          />
          <Route
            path="/Recharge"
            element={
              <ProtectedRoute isAuthenticated={isAuthenticated}>
                <Recharge />
              </ProtectedRoute>
            }
          />

          {/* Catch-all route for undefined paths */}
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </div>
      <div className="app-footer">
        <FooterScreen />
      </div>
    </div>
  );
}

export default App;