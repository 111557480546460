import React, { useContext, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Transition } from "@headlessui/react";
import { RxHamburgerMenu } from "react-icons/rx";
// import { LuMenuSquare } from "react-icons/lu";
import images from "../../Constants/images";
import { AuthUserContext } from "../Context/AuthUserContext";
import { getUserDetails, downloadFromStore } from "../../apiUtils";

const Navbar = () => {
  const { authToken, clearAuthData, isAuthenticated } = useContext(AuthUserContext);
  const navigate = useNavigate();
  const [balance, setBalance] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      if (authToken) {
        try {
          const userDetails = await getUserDetails(authToken);
          setBalance(userDetails.coins.balance);
        } catch (error) {
          console.error("Error fetching balance:", error);
        }
      }
    };
    fetchData();
  }, [authToken]);

  const handleLogout = () => {
    clearAuthData();
    navigate("/Login");
    setIsMenuOpen(false); // Close menu after logout
  };

  const handleLogoClick = (e) => {
    e.preventDefault();
    navigate(isAuthenticated ? "/Home" : "/");
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  return (
    <nav className="p-4 z-50 relative text-base md:text-lg font-bold">
      <div className="container mx-auto flex justify-between items-center">
        <a href={isAuthenticated ? "/Home" : "/"} onClick={handleLogoClick}>
          <img
            src={images.BlazeIconLogo}
            alt="Blaze"
            className="h-8 w-auto sm:h-10 md:h-12 lg:h-16"
          />
        </a>
        <div className="hidden md:flex space-x-4 items-center">
          <Link to="/FAQ" className="text-white hover:text-gray-300">FAQ</Link>
          {isAuthenticated ? (
            <>
              <div className="flex items-center space-x-1">
                <span className="text-white text-xs md:text-sm">My Balance:</span>
                <img src={images.MyBalanceCoinIcon} alt="Balance" className="h-4 w-4" />
                <span className="text-white text-xs md:text-sm">{balance}</span>
              </div>
              <button onClick={handleLogout} className="text-white hover:text-gray-300">Logout</button>
            </>
          ) : (
            <>
              <Link to="/Login" className="text-white hover:text-gray-300">Login</Link>
              <a onClick={downloadFromStore} className="text-white hover:text-gray-300">Download App</a>
            </>
          )}
        </div>

        {/* Mobile menu button */}
        <div className="md:hidden">
          <button onClick={toggleMenu} className="text-white">
            <RxHamburgerMenu className="h-6 w-6" />
          </button>
        </div>
      </div>

      {/* Mobile Menu */}
      <Transition
        show={isMenuOpen}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <div className={`md:hidden absolute top-16 right-0 bg-opacity-75 z-50`}>
          <div className="flex flex-col items-end pr-4 space-y-2">
            <Link to="/FAQ" className="text-white mt-8  py-2 rounded-md" onClick={closeMenu}>
              FAQ
            </Link>
            {isAuthenticated ? (
              <>
                <div className="flex items-center space-x-1">
                <span className="text-white text-xs md:text-sm">My Balance:</span>
                <img src={images.MyBalanceCoinIcon} alt="Balance" className="h-4 w-4" />
                <span className="text-white text-xs md:text-sm">{balance}</span>
              </div>
                <button onClick={handleLogout} className="text-white  py-2 rounded-md">
                  Logout
                </button>
              </>
            ) : (
              <>
                <Link to="/Login" className="text-white py-2 rounded-md" onClick={closeMenu}>
                  Login
                </Link>
                <a
                  onClick={() => {
                    downloadFromStore();
                    closeMenu();
                  }}
                  className="text-white py-2 rounded-md"
                >
                  Download App
                </a>
              </>
            )}
          </div>
          <button className="absolute top-0 right-0 p-2 text-white rounded-md" onClick={closeMenu}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="h-6 w-6">
              <path d="M6 18L18 6" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M6 6l12 12" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </button>
        </div>
      </Transition>
    </nav>
  );
};

export default Navbar;