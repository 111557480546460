import React from "react";
import "../BlazeS6/blazescreen6.css";
import images from "../../Constants/images";
import { AiFillCheckCircle } from "react-icons/ai";
import AppStoreLinks from "../AppStoreLinks";

const BlazeScreen6 = () => {
  return (
    <div className="m-auto flex flex-col sm:flex-row items-center h-80 w-9/12 bg-gradient-to-r from-[#642EFF] to-[#D52EFF] rounded-2xl">
      <div className="p-5 text-part relative left-0 py-8 sm:left-20" style={{flex:.5}}>
        <p className="text-lg md:text-xl font-extrabold text-white pb-5">
          Experience the future of live streaming
        </p>
        <div className="flex flex-col gap-2 text-base font-black md:text-lg">
          <div className="checkers-text flex items-center">
            <AiFillCheckCircle />
            <p>Seamless experience</p>
          </div>
          <div className="checkers-text flex items-center">
            <AiFillCheckCircle />
            <p>Interactive features</p>
          </div>
          <div className="checkers-text flex items-center">
            <AiFillCheckCircle />
            <p>Engaging community</p>
          </div>
        </div>
        <div className="">
          <p className="text-white py-2 text-base md:text-lg">Download the App</p>
          <AppStoreLinks />
        </div>
      </div>

      <div className="image-2" style={{flex:.5}}>
        <img className="hidden w-36 h-36 sm:flex sm:w-full sm:h-full  aspect-square" style={{objectFit: 'contain'}} src={images.BlazeIcons}/>
      </div>

      {/* <img style={{display: 'relativ'}} src={images.BlazeMobileBanner} /> */}

      {/* <div className="banner-6 relative flex items-center bg-green-300"> */}

      {/* <div className="flex flex-col justify-center pl-8 bg-red-400 absolute left-0 top-20 bottom-20">

        <p className="text-sm font-extrabold text-white">
          Experience the future of live streaming
        </p>
   

      <div className="flex flex-col gap-2 text-xs">
        <div className="checkers-text flex items-center">
          <AiFillCheckCircle />
          <p>Seamless experience</p>
        </div>
        <div className="checkers-text flex items-center">
          <AiFillCheckCircle />
          <p>Interactive features</p>
        </div>
        <div className="checkers-text flex items-center">
          <AiFillCheckCircle />
          <p>Engaging community</p>
        </div>
      </div>

      <div>
        <p className="text-white text-xs">Download the App</p>
        <AppStoreLinks />
      </div>
    </div> */}

      {/* </div> */}
    </div>
  );
};

export default BlazeScreen6;
