import React from "react";
import { Navigate, NavLink } from "react-router-dom";
import { useContext } from "react";
import { AuthUserContext } from "../Components/Context/AuthUserContext";

const ProtectedRoute = ({ children }) => {
  const { isAuthenticated } = useContext(AuthUserContext);

  if (!isAuthenticated) {
    // console.log("User not authenticated, redirecting to Landing...");
    <NavLink exact='true' to='/HomePage' />
  }

  return isAuthenticated ? children : <NavLink exact='true' to="/Home" />;
};

export default ProtectedRoute;
