import React from 'react';
import { useLocation } from 'react-router-dom';
import PaymenteSuccess from '../Components/PaymentSuccess'; // Import RechargeSuccess component
import PaymentFailed from '../Components/PaymentFailed';   // Import RechargeFailed component

const StripeCallback = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const msg = queryParams.get('msg');
  const status = queryParams.get('status')?.toLowerCase(); // Ensure case-insensitive check

  return (
    <div style={{ height: '100vh', textAlign: 'center', color: 'white', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
      {status === 'ok' ? (
        // Render RechargeSuccess component for successful payment
        <PaymenteSuccess message={msg || 'Payment was successful!'} />
      ) : (
        // Render RechargeFailed component for failed payment
        <PaymentFailed message={msg || 'Payment failed! Please try again.'} />
      )}
    </div>
  );
};

export default StripeCallback;
