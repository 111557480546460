import React from 'react';
import images from '../Constants/images';
// import './PaymentSuccess.css'; 

const PaymentSuccess = () => {
  const purchasedCoins = 1000;
  const existingCoins = 300;
  const totalCoins = purchasedCoins + existingCoins;

  const handleContinue = () => {
    // Navigate to the next page or perform an action
    console.log('Continue clicked');
  };

  return (
    <div className="container mx-auto px-4 py-8">
   
      <div className="flex flex-col items-center justify-center text-center">
        {/* below is image */}
        <div className="text-green-500 text-5xl">
            <img src={images.payStripePass}/>
        </div>
        <h1 className="text-3xl font-bold mt-4">Payment Successful - ₹399</h1>
        <p className="text-lg mt-2">Your payment has been received successfully. Coins have been added to your wallet.</p>
        <div className="flex items-center justify-center mt-4">
          <span className="text-yellow-500 text-2xl mr-2">⭐</span>
          <span className="text-2xl">{purchasedCoins}</span>
        </div>
      </div>
      <div className="flex flex-col items-center gap-2 mt-8">
        <div className="flex justify-between gap-2 items-start">
          <span>Purchased</span>
          <span>{purchasedCoins}</span>
        </div>
        <div className="flex justify-between gap-2 items-center">
          <span>Existing</span>
          <span>{existingCoins}</span>
        </div>
        <div className="flex justify-between gap-2 items-center">
          <span className="font-bold">Total</span>
          <span className="font-bold">{totalCoins}</span>
        </div>
      </div>
      <button className="bg-blue-500 text-white rounded-full px-4 py-2 mt-8" onClick={handleContinue}>
        Continue
      </button>
    </div>
  );
};

export default PaymentSuccess;