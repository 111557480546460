import React, { createContext, useState, useEffect, useCallback } from 'react';

// Create a single AuthUserContext
export const AuthUserContext = createContext();

// AuthUserProvider component
export const AuthUserProvider = ({ children }) => {
  const [authToken, setAuthToken] = useState(null);
  const [user, setUser] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false); // Track authentication status

  // Load user data and token from localStorage on initial render
  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    const storedToken = localStorage.getItem('authToken');

    if (storedUser && storedToken) {
      try {
        const parsedUser = JSON.parse(storedUser);
        setUser(parsedUser);
        setAuthToken(storedToken);
        setIsAuthenticated(true); // Set isAuthenticated to true if both user and token exist
      } catch (error) {
        console.error("Error parsing stored data:", error);
        clearAuthData(); // Clear data on error
      }
    }
  }, []); // Empty dependency array, only run once on component mount

  // Save user data and token to localStorage whenever they change
  useEffect(() => {
    if (user && authToken) {
      try {
        localStorage.setItem('user', JSON.stringify(user));
        localStorage.setItem('authToken', authToken);
        // console.log("Data saved to localStorage:", { user, authToken });
      } catch (error) {
        console.error("Error saving to localStorage:", error);
      }
    }
  }, [user, authToken]);

  // Function to clear user and token data
  const clearAuthData = () => {
    localStorage.removeItem('user');
    localStorage.removeItem('authToken');
    setUser(null);
    setAuthToken(null);
    setIsAuthenticated(false);
    // console.log("User logged out, data cleared from storage");
  };

  // Memoized updateAuth function
  const updateAuth = useCallback((token, userData) => {
    // console.log("updateAuth called with token:", token, "and userData:", userData);
    if (token && userData) {
      setAuthToken(token);
      setUser(userData);
      setIsAuthenticated(true);
    } else {
      clearAuthData();
    }
  }, []);

  return (
    <AuthUserContext.Provider value={{ authToken, user, isAuthenticated, setUser, updateAuth, clearAuthData }}>
      {children}
    </AuthUserContext.Provider>
  );
};
