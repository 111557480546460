import React, { useState, useEffect, useContext } from "react";
import images from "../../Constants/images";
import "../Recharge/recharge.css";
import CoinCard from "./CoinCard";
import { getProductsList, coinRecharge } from "../../apiUtils";
import { AuthUserContext } from "../Context/AuthUserContext"; // Adjust the import path if necessary
import { useNavigate } from "react-router-dom"; // Import navigate hook

const Recharge = () => {
  const { authToken } = useContext(AuthUserContext); // Use AuthUserContext to get authToken
  const [webProducts, setWebProducts] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate(); // Initialize navigate function

  useEffect(() => {
    const fetchProducts = async () => {
      if (!authToken) {
        console.error("Token is missing, skipping fetch");
        setLoading(false);
        setError("Token is missing");
        return;
      }

      try {
        setLoading(true);
        const data = await getProductsList(authToken);

        if (data.status === "ok") {
          setWebProducts(data.products.web || {});
        } else {
          console.error("Failed to fetch products:", data.message);
          setError(data.message || "Failed to fetch products");
        }
      } catch (error) {
        console.error("Error fetching products:", error);
        setError("Error fetching products");
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, [authToken]);

  const handleCoinBagClick = async (productId) => {
    if (!authToken) {
      console.error("Token is missing, cannot handle coin bag click");
      return;
    }

    try {
      // Call coinRecharge and handle redirection based on response
      const response = await coinRecharge(authToken, "default", productId);

      if (response.status === "ok") {
        // Navigate to a callback page on successful payment
        navigate(`/stripe/callback?msg=${encodeURIComponent("Payment successful")}&status=ok`);
      } else {
        // Navigate to callback page with failure message
        navigate(`/stripe/callback?msg=${encodeURIComponent(response.message || "Payment failed")}&status=error`);
      }
    } catch (error) {
      console.error("Error during coin recharge:", error);
      // Navigate to callback page with failure status if error occurs
      navigate(`/stripe/callback?msg=${encodeURIComponent("Payment failed")}&status=error`);
    }
  };

  if (loading) {
    return <div className="loading-container"><p className="text-white">Loading...</p></div>;
  }

  if (error) {
    return <div className="error-container"><p className="text-white">Error: {error}</p></div>;
  }

  const products = webProducts.default || {};

  return (
    <div>
      <div className="pt-10 px-5 sm:ml-10">
        <p className="Recharge-Heading pb-4 text-2xl font-black sm:text-5xl ">
          Recharge Coins
        </p>
        <div className="sm:flex justify-between items-center pt-0">
          <p className="text-white sm:text-xl">
            Recharge your wallet to support your favourite creators
          </p>
        </div>
      </div>

      <div className="GrayCoinContainer px-4 w-full sm:w-11/12 md:11/12 lg:w-11/12 m-auto mt-10 py-5 grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-4 rounded-xl">
        {Object.keys(products).length > 0 ? (
          Object.keys(products).map((productId) => {
            const product = products[productId];
            return (
              <CoinCard
                key={productId}
                rechargecoinimage={images[`${product.card.coin}Coin`]} // Ensure the image exists
                rechargenumeration={`${product.card.coin} Coins`}
                rechargecost={`Get it @ ₹${product.card.inr}`}
                onClick={() => handleCoinBagClick(productId)}
              />
            );
          })
        ) : (
          <p className="text-white">No products available</p>
        )}
      </div>
    </div>
  );
};

export default Recharge;
