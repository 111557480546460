import React from "react";
import images from "../Constants/images";
import { downloadFromStore } from "../apiUtils";

const AppStoreLinks = () => {
  return (
    <div className="flex flex-1 space-x-4">
      {/* Apple Store Image */}
      <img
        src={images.AppleStorePNG}
        className="w-24 h-8 sm:w-32 sm:h-10 md:w-36 md:h-12 lg:w-40 lg:h-14 cursor-pointer"
        style={{ objectFit: "contain" }}
        alt="Apple Store"
        onClick={downloadFromStore}
      />
      
      {/* Play Store Image */}
      <img
        src={images.PlayStorePNG}
        className="w-24 h-8 sm:w-32 sm:h-10 md:w-36 md:h-12 lg:w-40 lg:h-14 cursor-pointer"
        style={{ objectFit: "contain" }}
        alt="Play Store"
        onClick={downloadFromStore}
      />
    </div>
  );
};

export default AppStoreLinks;
