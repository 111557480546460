import Slider from "react-slick";
import "../../../node_modules/slick-carousel/slick/slick.css";
import "../../../node_modules/slick-carousel/slick/slick-theme.css";
import React from "react";
import SingleCard1 from "./SingleCard1";
import images from "../../Constants/images";

const Slider1Landing1 = () => {
  const settings = {
    customPaging: function () {
      return (
        <div
        className="md:hidden"
          style={{
            height: 10,
            width: 10,
            marginTop:30,
            borderRadius:50,
            backgroundColor: "white",
          }}
        />
      );
    },
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: false,
    dots: false,
    arrows: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          infinite: true,
          arrows: true,
        },
      },
    ],
  };

  return (
    <div className="px-12">
      <Slider {...settings}>
        <SingleCard1
          clipart={images.Mic}
          heading={"Share what you love"}
          paragh={
            "Indulge your love for design when you host Airbnb guests, who are drawn to authentic travel in creatively curated spaces."
          }
        />
        <SingleCard1
          clipart={images.Ninja}
          heading={"Host how you want to"}
          paragh={
            "Airbnb gives you the tools and support to earn extra income, be your own boss and invest in your passions."
          }
        />
        <SingleCard1
          clipart={images.Celebration}
          heading={"Share what you love"}
          paragh={
            "Indulge your love for design when you host Airbnb guests, who are drawn to authentic travel in creatively curated spaces."
          }
        />
        <SingleCard1
          clipart={images.Joystick}
          heading={"Host how you want to"}
          paragh={
            "Airbnb gives you the tools and support to earn extra income, be your own boss and invest in your passions."
          }
        />
      </Slider>
    </div>
  );
};

export default Slider1Landing1;
