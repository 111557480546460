import React from "react";
import images from "../Constants/images";

function PRCoinBag({ coinValue, discountedPrice, actualPrice, onClick }) {
  return (
    <div className="h-28 w-28 p-2 rounded-lg md:h-48 md:w-48 lg:h-72 lg:w-72 bg-white" onClick={onClick}>
      <div className="flex items-center justify-center gap-2">
        <div>
          <img className="h-4 w-4" src={images.PcIcon} />
        </div>
        <p className="text-xs md:text-2xl font-semibold">{coinValue}</p>
      </div>

      <div className="flex flex-col items-center">
        <div>
          <img className="h-12 w-12 md:h-20 md:w-20 lg:h-40 lg:w-40" src={images.PrCoinBag} />
        </div>
        <div className="flex flex-col items-center">
          <p className="text-xs md:text-2xl font-medium">₹{discountedPrice}</p>
          <p className="text-xs discounted-price md:text-xl">${actualPrice}</p>
        </div>
      </div>
    </div>
  );
}

export default PRCoinBag;
