import React from "react";
import "../Carausel2Landing/card2style2.css";

const Card2Style2 = (props) => {
  const { paragraph, writer, place } = props;

  return (
    <div className="card2-style2 w-11/12 h-76 text-xs md:flex-col gap-5 pb-5 mx-auto rounded-lg flex-1  text-white">
      <p className="text-center pt-5 px-5 text-base">{paragraph}</p>
      <p className="pt-8 font-bold text-center text-base md:text-lg">{writer}</p>
      <p className="place font-bold pt-2 text-center text-base md:text-lg">{place}</p>
    </div>
  );
};

export default Card2Style2;
