import fetchJsonp from 'fetch-jsonp';
import { useNavigate } from "react-router-dom";


const BASE_URL = 'https://blazeliveapp.appspot.com/api';

// import { useContext } from 'react';
// import { AuthUserContext } from './Components/Context/AuthUserContext';
// const { authToken, user, updateAuth } = useContext(AuthUserContext);


// Generalized fetchAPI function using fetchJsonp
const fetchAPI = async (endpoint) => {
  const url = `${BASE_URL}/${endpoint}`;
  try {
    const response = await fetchJsonp(url, {
      jsonpCallbackFunction: '' // Use the appropriate callback function name expected by the server
    });
    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.error('Fetch error:', error);
    throw error;
  }
};

// Specific API calls using fetchAPI
const loginUser = async (identifier, fn = "?") => {
  // Encode query parameters
  const endpoint = `user-login?query=${encodeURIComponent(identifier)}`;
  return fetchAPI(endpoint);
};

export const useAuth = () => {
  //getting token from local storage
  const user = localStorage.getItem('token')
  //checking whether token is preset or not
  if (user) {
      return true;
  } else {
      return false
  }
};

const verifyOtp = async (identifier, otp) => {
  // Determine the correct parameter name based on the identifier
  const paramName = identifier.includes('@') || isNaN(identifier) ? 'username' : 'mobile';
  
  const endpoint = `user-verifyotp?${paramName}=${encodeURIComponent(identifier)}&otp=${encodeURIComponent(otp)}`;
  return fetchAPI(endpoint);
};

const getToken = async (identifier, otp) => {
  const endpoint = `token?username=${encodeURIComponent(username)}&otp=${encodeURIComponent(otp)}`;
  // console.log("endpoint token",endpoint)
  return fetchAPI(endpoint);
};

const getProductsList = async (token,fn = "?") => {
  const endpoint = `productslist?token=${encodeURIComponent(token)}`;
  // console.log("its the product list endpoint ",endpoint)
  return fetchAPI(endpoint);
}

const getUserDetails = async (token,fn = "?") => {
  const endpoint = `user-fetchdetails?token=${encodeURIComponent(token)}`;
  // console.log("its userdetails endpoint ", endpoint)
  return fetchAPI(endpoint);
}

const coinRecharge = async (token, listingType, productId, successMsg = "PaymentSuccess") => {
  // Construct your backend endpoint to create a Stripe payment session
  const endpoint = `https://blazeliveapp.appspot.com/api/coinrecharge?action=webrecharge&listingtype=${listingType}&productid=${productId}&token=${encodeURIComponent(token)}`;

  try {
    // Send request to backend to create a payment session and get payment URL
    const response = await fetch(endpoint, { method: 'POST', headers: { 'Content-Type': 'application/json' }});
    const data = await response.json();

    if (data.status === 'ok' && data.paymentUrl) {
      // Redirect user to the Stripe payment page in the same window
      window.location.href = data.paymentUrl;

      // Stripe will handle the payment and then redirect the user to /stripe/callback
    } else {
      console.error("Failed to initiate recharge:", data.message);
      // Redirect to the callback URL with an error message and status
      window.location.href = `/stripe/callback?msg=${encodeURIComponent(data.message || "PaymentFailed")}&status=error`;
    }
  } catch (error) {
    console.error("Error during coin recharge:", error);
    // Redirect to the callback URL with an error message and status
    window.location.href = `/stripe/callback?msg=${encodeURIComponent("PaymentFailed")}&status=error`;
    throw error; // Rethrow the error if necessary
  }
};




// const paymentGatewayLink = "https://checkout.stripe.com/c/pay/cs_live_a1P6YJgkyDLoiZS5mgEJytsDvsoMpFXvXEnNviVnGmafCcnybF5efyEdpE#fidkdWxOYHwnPyd1blppbHNgWjA0T21Pdm9MZEhqanU3N3V0YnxfQkAyXVB0c2xpR2JxaDFRV0FNMDFJd1JHb0Y3RFxtZEZJNFwxQlVhUDV%2FQlJ%2FVTBNYGF1d1dycV9qQGpMcGcwYHZRY3RpNTVJV3Nga31ITycpJ2hsYXYnP34naHBsYSc%2FJ0tEJykndmxhJz8nS0QnKSdicGxhJz8nS0QneCknZ2BxZHYnP15YKSdpZHxqcHFRfHVgJz8ndmxrYmlgWmxxYGgnKSd3YGNgd3dgd0p3bGJsayc%2FJ21xcXV2PyoqZ2lkf2BpbHNgZHV1K2R1dXZ1anErZmpoJ3gl"

const downloadFromStore = () => {
    const userAgent = navigator.userAgent || window.opera;
  
    if (/android/i.test(userAgent)) {
      window.location.href = "https://play.google.com/store";
    } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      window.location.href = "https://apps.apple.com";
    } else {
      window.location.href = '/';
    }
  };

export { loginUser, verifyOtp, getToken, getProductsList,getUserDetails,coinRecharge, downloadFromStore };
