import React from "react";

function PrivacyPolicy() {
  return (
    <div className="p-6 min-h-screen">
      <h1 className="text-white text-center text-3xl font-bold mb-4">
        Privacy Policy
      </h1>
      <div className="leading-relaxed text-base text-white font-montserrat box-border -mx-4 text-justify px-[20%] custom-style">
        <p>
          <strong>Welcome to Blaze</strong>
          <br />
          <br />
          Blaze is the ultimate platform for live streaming and social
          connection, where subscribers can support and engage with creators.
          This privacy policy applies to subscribers, creators, and all users of
          our platform, and is part of our Terms of Use. By using our platform,
          you agree that your personal information that you provide directly to
          us or that we collect through your use of the platform, may be
          transferred to and stored in the United States and handled as
          described in this Policy.
          <br />
          <br />
          <strong>Information You Provide Through Your Account</strong>
          <br />
          <br />
          This is information that you provide to us through text fields, such
          as your name, payment information and benefits. The information we
          collect differs depending on if you make an account, become a
          subscriber, or become a creator.
        </p>
        <ul className="list-disc my-4 ml-0 mr-0 pl-10">
          <li>Email address</li>
          <li>Username / Instagram Username</li>
          <li>Mobile Number</li>
          <li>PAN Number</li>
          <li>Aadhaar Number</li>
          <li>Bank Account Details</li>
        </ul>
        <br />
        You may also sign up using a Facebook, Instagram or Google account. We
        will ask permission to access basic information from your Facebook,
        Instagram or Google account, such as your name, email, and profile
        picture. You can choose to stop sharing that information with us at any
        time by going to Facebook or Google to remove Blaze’s access to that
        account and your ability to log in.
        <br />
        <br />
        <strong>Subscribers or Fans</strong>
        <br />
        A subscriber is someone who joins Blaze’s platform to support a
        creator’s content. You may have to make payment on our platform to avail
        the benefits or purchase any services offered by a creator. We assure
        you that the payments get processed by most trusted third party payment
        gateways.
        <br />
        We collect and process information about the creators you support, the
        level at which you support them, what benefits you receive and how often
        you support them.
        <br />
        <br />
        <strong>Creators or Artists</strong>
        <br />
        <br />A creator is someone who creates and provides content for their
        subscribers through Blaze’s platform. To become a creator, you must sign
        up on Blaze App. To receive payouts you have to provide us your
        preferred payment mode with your details e.g. UPI / Bank Transfer etc.
        You must also provide us with additional information for tax purposes.
        <ul className="list-disc my-4 ml-0 mr-0 pl-10">
          <li>Legal name</li>
          <li>Address Proof</li>
          <li>PAN Card / Aadhar Card</li>
        </ul>
        <br />
        <br />
        <strong>Additional Information We Collect</strong>
        <br />
        <br />
        We collect information automatically as you navigate the site or through
        our third party analytics providers. We may store usage information such
        as the type of device you use to access Blaze, your operating system,
        browser type, IP address, and device ID, the pages you visit or request,
        links clicked, referring sites, user interactions and your search terms.
        We also derive your location from your self-disclosed country, your IP
        address.
        <br />
        <br />
        We also collect the information from your interaction with our platform
        in terms of user comments, services browsed etc.
        <br />
        <br />
        <strong>How We Use Your Information</strong>
        <br />
        <br />
        We process your information to:
        <ul className="list-disc my-4 ml-0 mr-0 pl-10">
          <li>provide Blaze services to you</li>
          <li>allow you to sign in to your account</li>
          <li>allow you to join a creator’s membership program on Blaze</li>
          <li>process a creator’s membership payments</li>
          <li>
            send you emails relevant to your usage, as controlled by your email
            preferences
          </li>
          <li>reply to your questions</li>
          <li>market Blaze to you</li>
          <li>
            understand how you use the service and create better tools for
            creators to serve subscribers
          </li>
          <li>
            conduct research and development to improve Blaze and develop future
            products
          </li>
          <li>prevent fraud and abuse on Blaze</li>
          <li>ship merchandise to you if a benefit includes it</li>
          <li>
            allow us to provide you with reasonable accommodation, if you notify
            us of a disability
          </li>
        </ul>
        <br />
        <br />
        <strong>Information Sharing</strong>
        <br/><br/>
        We share the sensitive personal information to any third party without obtaining the prior consent of the user in the following limited circumstances:
        <br/><br/>
        (a) When it is requested or required by law or by any court or governmental agency or authority to disclose, for the purpose of verification of identity, or for the prevention, detection, investigation including cyber incidents, or for prosecution and punishment of offences. These disclosures are made in good faith and belief that such disclosure is reasonably necessary for enforcing these Terms; for complying with the applicable laws and regulations.

       
        <strong>Information We Share with Creators</strong>
        <br />
        <br />
        By becoming a subscriber of a creator, you agree to have the following
        information shared with that creator:
        <ul className="list-disc my-4 ml-0 mr-0 pl-10">
          <li>your name and other profile information you’ve provided</li>
          <li>any messages you send creators through Blaze</li>
          <li>
            some aggregated and anonymized data about how you use Blaze that
            cannot be linked back to you or to any individual user
          </li>
        </ul>
        <br />
        <br />
        <strong>Information Security</strong>
        <br />
        <br />
        We take appropriate security measures to protect against unauthorized
        access to or unauthorized alteration, disclosure or destruction of data.
        These include internal reviews of our data collection, storage and
        processing practices and security measures, including appropriate
        encryption and physical security measures to guard against unauthorized
        access to systems where we store personal data.
        <br />
        <br />
        All information gathered on our Website is securely stored within our
        controlled database. The database is stored on servers secured behind a
        firewall; access to the servers is password-protected and is strictly
        limited. However, as effective as our security measures are, no security
        system is impenetrable. We cannot guarantee the security of our
        database, nor can we guarantee that information you supply will not be
        intercepted while being transmitted to us over the Internet. And, of
        course, any information you include in a posting to the discussion areas
        is available to anyone with Internet access.
        <br />
        <br />
        However the internet is an ever evolving medium. We may change our
        Privacy Policy from time to time to incorporate necessary future
        changes. Of course, our use of any information we gather will always be
        consistent with the policy under which the information was collected,
        regardless of what the new policy may be.
        <br />
        <br />
        <br />
        <br />
        <strong>Cookies</strong>
        <br />
        <br />
        To improve the responsiveness of the sites for our users, we may use
        "cookies", or similar electronic tools to collect information to assign
        each visitor a unique, random number as a User Identification (User ID)
        to understand the user's individual interests using the Identified
        Computer. Unless you voluntarily identify yourself (through
        registration, for example), we will have no way of knowing who you are,
        even if we assign a cookie to your computer. The only personal
        information a cookie can contain is information you supply (an example
        of this is when you ask for our Personalised Horoscope). A cookie cannot
        read data off your hard drive. Our advertisers may also assign their own
        cookies to your browser (if you click on their ads), a process that we
        do not control.
        <br />
        <br />
        Our web servers automatically collect limited information about your
        computer's connection to the Internet, including your IP address, when
        you visit our site. (Your IP address is a number that lets computers
        attached to the Internet know where to send you data -- such as the web
        pages you view.) Your IP address does not identify you personally. We
        use this information to deliver our web pages to you upon request, to
        tailor our site to the interests of our users, to measure traffic within
        our site and let advertisers know the geographic locations from where
        our visitors come.
        <br />
        <br />
        <strong>Grievance Redressal</strong>
        <br />
        <br />
        Redressal Mechanism: Any complaints, abuse or concerns with regards to
        content and or comment or breach of these terms shall be immediately
        informed to the designated Grievance Officer as mentioned below via in
        writing or through email signed with the electronic signature to the
        “Grievance Officer”
        <br />
        <br />
        Mr. Shailesh (Grievance Officer) <a className="text-blue-500" href="https://www.blazelive.com/" target="_blank">https://www.blazelive.com/</a>
        <br />
        Blaze Live, 300 Delaware Avenue, STE 210 400, Wilmington, DE 19801
        <br />
        Email:support@blazelive.com
        <br />
        Ph: +1-30291017
        <br />
        <p></p>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
