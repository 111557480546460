import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import images from "../../Constants/images";
import RechargeBanner from "../RechargeBanner";
import RechargeCoin from "./RechargeCoin";
import BlazeScreen6 from "../BlazeS6/BlazeScreen6";
import { AuthUserContext } from "../Context/AuthUserContext";
import { getProductsList, getUserDetails, coinRecharge } from "../../apiUtils";

const Home = () => {
  const { authToken, user, updateAuth, isAuthenticated } =
    useContext(AuthUserContext);
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchData = async () => {
    setLoading(true);
    setError(null);

    try {
      if (authToken) {
        const [userDetailsResponse, productsResponse] = await Promise.all([
          getUserDetails(authToken),
          getProductsList(authToken),
        ]);

        if (userDetailsResponse.status === "ok") {
          setUserData(userDetailsResponse);
          updateAuth(authToken, userDetailsResponse.user);

          if (productsResponse.status === "ok") {
            const transformedProducts = Object.entries(
              productsResponse.products?.web || {}
            ).flatMap(([category, items]) =>
              Object.entries(items).map(([productId, product]) => ({
                productId,
                ...product.card,
                special: product.special || {},
              }))
            );
            setProducts(transformedProducts);
          } else {
            setError(`Error fetching products: ${productsResponse.message}`);
          }
        } else {
          setError(
            `Error fetching user details: ${userDetailsResponse.message}`
          );
        }
      } else {
        setError("User token not found");
      }
    } catch (error) {
      setError(`Fetch error: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (authToken && isAuthenticated) {
      fetchData();
    }
  }, [authToken, isAuthenticated]); // Check both authToken and isAuthenticated

  const handleCoinBagClick = async (productId) => {
    if (!authToken) {
      console.error("Token is missing, cannot handle coin bag click");
      return;
    }

    try {
      const response = await coinRecharge(authToken, "default", productId);

      if (response.status === "ok") {
        navigate(
          `/stripe/callback?msg=${encodeURIComponent(
            "Payment successful"
          )}&status=ok`
        );
      } else {
        navigate(
          `/stripe/callback?msg=${encodeURIComponent(
            response.message || "Payment failed"
          )}&status=error`
        );
      }
    } catch (error) {
      console.error("Error during coin recharge:", error);
      navigate(
        `/stripe/callback?msg=${encodeURIComponent(
          "Payment failed"
        )}&status=error`
      );
    }
  };

  if (loading) {
    return <p className="text-white text-center">Loading...</p>;
  }

  if (error) {
    return <p className="text-white text-center">Error: {error}</p>;
  }

  const bannerData = {
    imageUrl: "https://example.com/your-image.jpg",
    title: "Get 50% Extra Coins",
    description: "If you recharge through our special offer",
  };

  return (
    <div className="text-white">
      {userData ? (
        <>
          <div className="flex flex-col items-center justify-center md:flex-row md:gap-5">
            <div className="text-center flex items-start">
              <img
                src={userData.user.details.profilepic}
                className="rounded-lg 
              w-24 h-24 
              md:w-32 md:h-32 
              lg:w-40 lg:h-40
              object-cover"
                alt="Avatar"
              />
            </div>
            <div className="flex-col items-center md:flex:1 p-5">
              <div className="flex flex-col items-center mt-4 md:items-start">
                <p className="text-base md:text-lg lg:text-xl font-semibold">
                  {userData.user.username || "User Name"}
                </p>
              </div>

              <div className="flex justify-center md:justify-start">
                <div className="text-center p-1 flex flex-col justify-between">
                  <p className="text-base md:text-lg lg:text-xl font-semibold">
                    {userData.diamonds.earned || "0"}
                  </p>
                  <p className="text-base">💎 Earned</p>
                </div>
                <div className="inline-block h-[50px] min-h-[1em] w-0.5 self-stretch bg-neutral-100 opacity-100 dark:opacity-50"></div>
                <div className="text-center p-1 flex flex-col justify-between">
                  <p className="text-base md:text-lg lg:text-xl font-semibold">
                    {userData.followers || "0"}
                  </p>
                  <p className="text-base">Followers</p>
                </div>
                <div className="inline-block h-[50px] min-h-[1em] w-0.5 self-stretch bg-neutral-100 opacity-100 dark:opacity-50"></div>
                <div className="text-center p-1">
                  <p className="text-base md:text-lg lg:text-xl font-semibold">
                    {userData?.user?.following?.length || 0}
                  </p>
                  <p className="text-base">Following</p>
                </div>
              </div>

              {/* <div className="flex items-center justify-center md:justify-start">
                <button className="p-4 px-8 bg-[rgba(255, 255, 255, 1)] rounded-full border-[rgba(255, 255, 255, 1)]">
                  Edit Profile
                </button>
              </div> */}
            </div>
          </div>

          <div className="m-auto mt-10">
            <RechargeBanner
              imageUrl={images.BlogCardImg1}
              title={bannerData.title}
              description={bannerData.description}
            />
          </div>

          <div className="flex flex-col items-center mt-4 md:m-10">
            <div className="flex flex-col gap-2 items-center">
              <p className="text-3xl font-bold pt-4">Available Balance</p>
              <div className="flex items-center my-4">
                <img
                  src={images.MyBalanceCoinIcon}
                  alt="Balance Coin"
                  className="mr-2 h-4 md:h-8"
                />
                <p className="text-center">{userData.coins?.balance}</p>
              </div>
            </div>

            <div className="grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-2  gap-4 md:gap-10 py-4 justify-center">
              {products.map((product) => (
                <RechargeCoin
                  key={product.productId}
                  coins={product.coin}
                  rupee={product.inr}
                  onClick={() => handleCoinBagClick(product.productId)}
                />
              ))}
            </div>
          </div>
          <div className="my-8">
            <BlazeScreen6 />
          </div>
        </>
      ) : (
        <p className="text-center">No user data available</p>
      )}
    </div>
  );
};

export default Home;
