import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Button from "@mui/material/Button";

export default function FAQ() {
  return (
    <div className="px-20 py-10">
      <Accordion sx={{backgroundColor:'transparent', color:'white'}}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon style={{color:'white'}} />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          General FAQs
        </AccordionSummary>
        <AccordionDetails
          sx={{
            padding: "10px",
            display: "flex",
            justifyContent: "center", // Centers the content horizontally
            paddingBottom: "10px",
          }}
        >
          <div style={{ width: "100%", maxWidth: "800px", textAlign: "left" }}>
            <ul style={{ listStyle: "decimal", textAlign: "left" }}>
              <li>
                <h2>What is Blaze?</h2>
                <p style={{ listStyleType: "disc", marginLeft: "20px" }}>
                  ➜ Blaze is the ultimate platform for live streaming and social
                  connection. Whether you're a content creator, an artist, or
                  simply want to connect with like-minded people, Blaze has
                  everything you need to build your audience and grow your
                  community.
                </p>
              </li>

              <li>
                <h2>How can I download Blaze?</h2>
                <p style={{ listStyleType: "disc", marginLeft: "20px" }}>
                  ➜ You can download Blaze for free from the Google Play Store
                  (for Android) or the Apple App Store (for iOS).
                </p>
              </li>

              <li>
                <h2>Is Blaze available on the web?</h2>
                <p style={{ listStyleType: "disc", marginLeft: "20px" }}>
                  ➜ Currently, Blaze is only available as a mobile app. We are
                  working on a web version, so stay tuned for updates.
                </p>
              </li>

              <li>
                <h2>Do I need to pay to use Blaze?</h2>
                <p style={{ listStyleType: "disc", marginLeft: "20px" }}>
                  ➜ You can use the app for free, but there are optional in-app
                  purchases for virtual gifts.
                </p>
              </li>

              <li>
                <h2>How do I create a Blaze account?</h2>
                <p style={{ listStyleType: "disc", marginLeft: "20px" }}>
                  ➜ To create an account, download the app and sign up using
                  your mobile number or email address. You'll receive a
                  verification code to complete the registration.
                </p>
              </li>

              <li>
                <h2>Can I use Blaze without an account?</h2>
                <p style={{ listStyleType: "disc", marginLeft: "20px" }}>
                  ➜ No, you will need to create an account to fully enjoy all
                  the app's features and interact with other users.
                </p>
              </li>
            </ul>
          </div>
        </AccordionDetails>
      </Accordion>

      <Accordion sx={{backgroundColor:'transparent', color:'white'}}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon style={{color:'white'}}/>}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          Live Streaming FAQs:
        </AccordionSummary>
        <AccordionDetails
          sx={{
            padding: "10px",
            display: "flex",
            justifyContent: "center", // Centers the content horizontally
            paddingBottom: "10px",
          }}
        >
          <div style={{ width: "100%", maxWidth: "800px", textAlign: "left" }}>
            <ul style={{ listStyle: "decimal", textAlign: "left" }}>
              <li>
                <h2>How can I start a livestream on Blaze?</h2>
                <p style={{ listStyleType: "disc", marginLeft: "20px" }}>
                  ➜ To start a livestream, tap on the camera icon in the app and
                  follow the on-screen instructions. You can go live and share
                  your content with your followers.
                </p>
              </li>
              <li>
                <h2>Can I earn money by livestreaming on Blaze?</h2>
                <p style={{ listStyleType: "disc", marginLeft: "20px" }}>
                  ➜ Yes, Blaze offers monetization options for content creators.
                  You can earn money through virtual gifts in the live streams.
                </p>
              </li>
            </ul>
          </div>
        </AccordionDetails>
      </Accordion>

      <Accordion sx={{backgroundColor:'transparent', color:'white'}}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon style={{color:'white'}} />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          Technical FAQs:
        </AccordionSummary>
        <AccordionDetails
          sx={{
            padding: "10px",
            display: "flex",
            justifyContent: "center", // Centers the content horizontally
            paddingBottom: "10px",
          }}
        >
          <div style={{ width: "100%", maxWidth: "800px", textAlign: "left" }}>
            <ul style={{ listStyle: "decimal", textAlign: "left" }}>
              <li>
                <h2>How does Blaze ensure user safety?</h2>
                <p style={{ listStyleType: "disc", marginLeft: "20px" }}>
                  ➜ Blaze has safety measures in place, including content
                  moderation and reporting tools. We also encourage users to
                  follow community guidelines and report any inappropriate
                  content or behaviour.
                </p>
              </li>
              <li>
                <h2>Is my personal information secure on Blaze?</h2>
                <p style={{ listStyleType: "disc", marginLeft: "20px" }}>
                  ➜ We take your privacy seriously. Your personal information is
                  protected and used in accordance with our privacy policy. You
                  can review our privacy policy on the website.
                </p>
              </li>
              <li>
                <h2>
                  I'm experiencing technical issues with Blaze. What should I
                  do?
                </h2>
                <p style={{ listStyleType: "disc", marginLeft: "20px" }}>
                  ➜ If you encounter technical issues, check your internet
                  connection, update the app to the latest version, or contact
                  our support team through the app for assistance.
                </p>
              </li>
              <li>
                <h2>How can I report abusive behaviour or content?</h2>
                <p style={{ listStyleType: "disc", marginLeft: "20px" }}>
                  ➜ You can report abusive behaviour or content by using the
                  reporting tools within the app. Our moderation team will
                  review reports and take appropriate action.
                </p>
              </li>

              <li>
                <h2>How can I report abusive behaviour or content?</h2>
                <p style={{ listStyleType: "disc", marginLeft: "20px" }}>
                  ➜ You can report abusive behaviour or content by using the
                  reporting tools within the app. Our moderation team will
                  review reports and take appropriate action.
                </p>
              </li>
            </ul>
          </div>
        </AccordionDetails>
      </Accordion>

      <Accordion sx={{backgroundColor:'transparent', color:'white'}}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon style={{color:'white'}} />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          Coin Top-Up FAQs:
        </AccordionSummary>
        <AccordionDetails
          sx={{
            padding: "10px",
            display: "flex",
            justifyContent: "center", // Centers the content horizontally
            paddingBottom: "10px",
          }}
        >
          <div style={{ width: "100%", maxWidth: "800px", textAlign: "left" }}>
            <ul style={{ listStyle: "decimal", textAlign: "left" }}>
              <li>
                <h2>How do I top up my coin wallet?</h2>
                <p style={{ listStyleType: "disc", marginLeft: "20px" }}>
                  ➜ Blaze accepts various payment methods, including
                  credit/debit cards, UPI, net banking, and mobile wallets. You
                  can select your preferred payment method during the top-up
                  process.
                </p>
              </li>
              <li>
                <h2>What payment methods are accepted for coin top-up?</h2>
                <p style={{ listStyleType: "disc", marginLeft: "20px" }}>
                  ➜ Blaze accepts various payment methods, including
                  credit/debit cards, UPI, net banking, and mobile wallets. You
                  can select your preferred payment method during the top-up
                  process.
                </p>
              </li>
              <li>
                <h2>
                  I'm experiencing technical issues with Blaze. What should I
                  do?
                </h2>
                <p style={{ listStyleType: "disc", marginLeft: "20px" }}>
                  ➜ If you encounter technical issues, check your internet
                  connection, update the app to the latest version, or contact
                  our support team through the app for assistance.
                </p>
              </li>
              <li>
                <h2>Is there a minimum or maximum amount for coin top-up?</h2>
                <p style={{ listStyleType: "disc", marginLeft: "20px" }}>
                  ➜ The minimum and maximum top-up amounts may vary depending on
                  your payment method and location. Please check the app for the
                  specific limits applicable to your account.
                </p>
              </li>

              <li>
                <h2>Are there any fees associated with coin top-up?</h2>
                <p style={{ listStyleType: "disc", marginLeft: "20px" }}>
                  ➜ Blaze may apply a processing fee for certain top-up
                  transactions. The fee amount and details will be displayed
                  during the top-up process for your review before confirming
                  the transaction.
                </p>
              </li>
            </ul>
          </div>
        </AccordionDetails>
      </Accordion>

      <Accordion sx={{backgroundColor:'transparent', color:'white'}}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon style={{color:'white'}} />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          Diamond Redemption FAQs:
        </AccordionSummary>
        <AccordionDetails
          sx={{
            padding: "10px",
            display: "flex",
            justifyContent: "center", // Centers the content horizontally
            paddingBottom: "10px",
          }}
        >
          <div style={{ width: "100%", maxWidth: "800px", textAlign: "left" }}>
            <ul style={{ listStyle: "decimal", textAlign: "left" }}>
              <li>
                <h2>How do I redeem diamonds I've received as gifts?</h2>
                <p style={{ listStyleType: "disc", marginLeft: "20px" }}>
                  ➜ You can redeem diamonds by selecting "Withdraw Money" in the
                  profile section or clicking the “Withdraw” button in the
                  Diamonds section and following the on-screen instructions.
                  You'll need to meet the minimum redemption threshold before
                  you can initiate the process.
                </p>
              </li>
              <li>
                <h2>What is the minimum threshold for diamond redemption?</h2>
                <p style={{ listStyleType: "disc", marginLeft: "20px" }}>
                  ➜ The minimum threshold for diamond redemption is 5000
                  diamonds. You must accumulate at least this many diamonds
                  before you can convert them into real currency.
                </p>
              </li>
              <li>
                <h2>
                  How are diamonds converted into real currency during
                  redemption?
                </h2>
                <p style={{ listStyleType: "disc", marginLeft: "20px" }}>
                  ➜ The conversion rate for diamonds into real currency keeps
                  changing from time to time. Currently the conversion rate is
                  5:1. For example, if you have 100 diamonds, you will receive
                  20 currency upon redemption.
                </p>
              </li>
              <li>
                <h2>Is there a waiting period for diamond redemption?</h2>
                <p style={{ listStyleType: "disc", marginLeft: "20px" }}>
                  ➜ Yes, there may be a processing period for diamond
                  redemption. It typically takes 7-10 working days for your
                  requested redemption amount to be credited to your selected
                  withdrawal method.
                </p>
              </li>

              <li>
                <h2>
                  What withdrawal methods are available for diamond redemption?
                </h2>
                <p style={{ listStyleType: "disc", marginLeft: "20px" }}>
                  ➜ You can choose from various withdrawal methods, including
                  Bank Transfer, UPI, PayPal, or a digital wallet. Select your
                  preferred method during the redemption process and provide the
                  necessary details.
                </p>
              </li>

              <li>
                <h2>Are there any fees associated with diamond redemption?</h2>
                <p style={{ listStyleType: "disc", marginLeft: "20px" }}>
                  ➜ Blaze may apply transaction fees or currency conversion fees
                  during the redemption process, depending on your chosen
                  withdrawal method and location. These fees will be clearly
                  outlined during the redemption request.
                </p>
              </li>
            </ul>
          </div>
        </AccordionDetails>
      </Accordion>

      <Accordion sx={{backgroundColor:'transparent', color:'white'}}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon style={{color:'white'}}/>}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          Payout Settings FAQs:
        </AccordionSummary>
        <AccordionDetails
          sx={{
            padding: "10px",
            display: "flex",
            justifyContent: "center", // Centers the content horizontally
            paddingBottom: "10px",
          }}
        >
          <div style={{ width: "100%", maxWidth: "800px", textAlign: "left" }}>
            <ul style={{ listStyle: "decimal", textAlign: "left" }}>
              <li>
                <h2>Why do I need to update my Payout Settings?</h2>
                <p style={{ listStyleType: "disc", marginLeft: "20px" }}>
                  ➜ Updating your Payout Settings is necessary to receive the
                  earnings you've accumulated through gift redemptions. This
                  information ensures that we can securely transfer funds to
                  your preferred payout method.
                </p>
              </li>
              <li>
                <h2>How can I update my Payout Settings?</h2>
                <p style={{ listStyleType: "disc", marginLeft: "20px" }}>
                  ➜ To update your Payout Settings, go to your profile settings,
                  select "Payout Settings," and follow the prompts. You can add
                  or edit your bank account details, or other preferred payout
                  methods.
                </p>
              </li>

              <li>
                <h2>What payout methods are available for withdrawals?</h2>
                <p style={{ listStyleType: "disc", marginLeft: "20px" }}>
                  ➜ Blaze offers multiple payout methods, including bank
                  transfers, PayPal, and digital wallets. You can choose the
                  method that is most convenient for you.
                </p>
              </li>

              <li>
                <h2>Is my Payout Settings information secure?</h2>
                <p style={{ listStyleType: "disc", marginLeft: "20px" }}>
                  ➜ Yes, we take the security of your Payout Settings seriously.
                  Your information is encrypted and stored securely.
                </p>
              </li>

              <li>
                <h2>
                  Are there any fees associated with updating Payout Settings?
                </h2>
                <p style={{ listStyleType: "disc", marginLeft: "20px" }}>
                  ➜ Yes, we take the security of your Payout Settings seriously.
                  Your information is encrypted and stored securely.
                </p>
              </li>

              <li>
                <h2>
                  Can I change my Payout Settings after I've initiated a
                  withdrawal request?
                </h2>
                <p style={{ listStyleType: "disc", marginLeft: "20px" }}>
                  ➜ Once a withdrawal request has been initiated, you may not be
                  able to change your Payout Settings for that specific
                  transaction. It's important to ensure your settings are
                  accurate before requesting a withdrawal.
                </p>
              </li>

              <li>
                <h2>Are there any tax implications for withdrawals?</h2>
                <p style={{ listStyleType: "disc", marginLeft: "20px" }}>
                  ➜ It's essential to be aware of tax regulations in your
                  jurisdiction regarding income earned through live streaming
                  platforms. Blaze may provide tax documents or information to
                  assist you in reporting your earnings.
                </p>
              </li>
            </ul>
          </div>
        </AccordionDetails>
      </Accordion>

      {/* <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3-content"
          id="panel3-header"
        >
          Accordion Actions
        </AccordionSummary>
        <AccordionDetails>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
          malesuada lacus ex, sit amet blandit leo lobortis eget.
        </AccordionDetails>
        <AccordionActions>
          <Button>Cancel</Button>
          <Button>Agree</Button>
        </AccordionActions>
      </Accordion> */}
    </div>
  );
}
