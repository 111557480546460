import Slider1Landing1 from "../Carausel1Landing/Slider1Landing1";
import CardX from "../Carausel1Landing/CardX";
import images from "../../Constants/images";

export default function BlazeScreen4() {
  return (
    <div className="flex flex-col items-center">
      <div className="lg:mx-10 md:mx-10 w-full">
        <p className="text-2xl lg:text-5xl font-black text-center mt-10 bg-clip-text text-transparent bg-gradient-to-r from-[#ffc266] to-[#ff1e78]">
          Features
        </p>
        <p className="pt-2 pb-8 text-white text-base md:text-lg lg:text-xl text-center">
          Stream like a pro with our feature-packed live streaming platform designed for creators
        </p>

        {/* Mobile view */}
        <div className="w-full">
          <Slider1Landing1 />
        </div>

        {/* Tablet and PC view */}
        {/* <div className="hidden sm:grid grid-cols-1 md:grid-cols-2 gap-4 p-4 lg:p-8">
          <CardX
            clipart={images.Mic}
            heading="Live Shows"
            paragh="Host or join live streams with advanced features and filters, and enjoy cool gifting experience."
          />
          <CardX
            clipart={images.Ninja}
            heading="Live Battles"
            paragh="Invite others for a live face-off challenge for a winner takes all matches."
          />
          <CardX
            clipart={images.Celebration}
            heading="Live Parties"
            paragh="Create party rooms and invite your friends for virtual parties."
          />
          <CardX
            clipart={images.Joystick}
            heading="Live Games"
            paragh="Invite others for a live face-off challenge for a winner takes all matches."
          />
        </div> */}
      </div>
    </div>)};