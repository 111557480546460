import React, { useRef } from "react";
import { NavLink } from "react-router-dom";

function TermsOfUse() {
  // Manually create refs for each subheading
  const subheadingRefs = {
    A: useRef(null),
    B: useRef(null),
    C: useRef(null),
    D: useRef(null),
    E: useRef(null),
    F: useRef(null),
    G: useRef(null),
    H: useRef(null),
    I: useRef(null),
    J: useRef(null),
    K: useRef(null),
    L: useRef(null),
    M: useRef(null),
    N: useRef(null),
    O: useRef(null),
    P: useRef(null),
    Q: useRef(null),
    R: useRef(null),
    S: useRef(null),
    T: useRef(null),
    V: useRef(null),
    W: useRef(null),
    X: useRef(null),
    Y: useRef(null),
    Z: useRef(null),
  };

  // Function to scroll to a specific subheading
  const scrollToSubheading = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  return (
    <div className="p-6 min-h-screen leading-relaxed text-white font-montserrat box-border -mx-4 text-justify px-[10%] text-sm">
      <div class="row text-center text-[20px] px-[5%]">
        <br />
        <span class="text-3xl font-bold">Terms of Service</span>
        <h4 class="text-3xl font-bold">Last updated: 31st August 2024</h4>
      </div>
      <br />
      <br />
      <div className="text-[16px] leading-[1.4286] text-white font-sans font-montserrat box-border ml-[-15px] mr-[-15px] text-justify px-[10%]">
        <p>
          This document sets out the terms and conditions for use of domains
          www.blazelive.app (the “Site”), Blaze Live (the mobile app) and any
          features, subdomains, content, functionality, services, media,
          applications, Discussion Forums, or solutions offered on or through
          the Site (collectively referred to as the “Platform”). These terms and
          conditions apply to individuals who create content on the Platform
          (referred to as “Creators”) and individuals who consume the content on
          the Platform (referred to as “Followers”) collectively (“You”, “Your”,
          or “User(s)”). Unless otherwise specifically stated, the terms “You”,
          “Your”, or “User” shall refer to you, the user of the Platform or any
          legal entity for whom you are an authorized employee or agent on
          behalf of when you are using or accessing the Platform and includes
          all Creators and Followers. These terms and conditions including the{" "}
          <NavLink
            end
            to={"/PrivacyPolicy"}
            className="text-[#428bca] hover:text-blue-700"
          >
            Privacy Policy
          </NavLink>
          {", "}
          <NavLink
            end
            to={"/PlatformGuidelines"}
            className="text-[#428bca] hover:text-blue-700"
          >
            Platform Guidelines
          </NavLink>
          {", "}
          <NavLink
            end
            to={"/content-guidelines"}
            className="text-[#428bca] hover:text-blue-700"
          >
            Content &amp; Creation Guidelines
          </NavLink>{" "}
          and Data Processing Agreement (collectively referred to as the
          “Agreement”), define the relationship and responsibilities between You
          and Blaze (as defined herein) in using the Platform. Your access to
          the Platform is subject to Your acceptance and agreement of these
          terms and conditions. Hence, please take Your time to read this
          Agreement. When we speak of 'Blaze' , ‘we’, ‘us’, and ‘our’, we mean
          Blaze – a company incorporated in India. Please read the Agreement and
          all other rules and policies made available or published on the
          Platform as they shall govern Your use of the Platform and the
          services offered therein. From time-to-time, versions of the
          above-mentioned policies and terms are made available on the Platform
          for Your reference and to understand how we handle Your personal
          information. By using or visiting the Platform and services provided
          to You on, from, or through the Platform, or by clicking the button
          during the registration process to create an account on the Platform,
          You are expressly agreeing to the terms of the Agreement and any other
          terms that are updated from time to time. If You do not agree to any
          of these terms or do not wish to be bound by the same, please do not
          use the Platform in any manner. By entering into this Agreement, You
          represent and warrant that You have full power and authority to enter
          into this Agreement and doing so will not violate any other agreement
          which You are a party.U.S. federal and state laws will primarily
          govern the platform, especially for users and creators outside India.
          This includes specific references to laws such as the Digital
          Millennium Copyright Act (DMCA), Communications Decency Act (CDA), and
          California Consumer Privacy Act (CCPA) where applicable.
          <br />
          <br />
          <strong>
            <u className="underline">About Blaze Platform:</u>
          </strong>{" "}
          The Platform is owned, managed and operated by ENTECHPRISE, a private
          company incorporated in the USA.Blaze Platform is an app-based service
          that provides an online platform to create, design, and publish
          digital services such as live events, and 1-1 interactions (
          <strong>BlazeFeatures</strong>). Any content created by a Creator on
          the Platform including BlazeFeatures is referred to as
          <strong>“Content”</strong>. Any person looking to create Content,
          either in the form of live-streaming or pre-recorded format, can do so
          in a more organized and systematic manner using Platform, for the
          Platform provides several additional features than just content
          creation, including integration of third-party payment processors.
          Facilitation of Content creation along with all of the features and
          services offered on the Platform shall be referred to as{" "}
          <strong>“Blaze Services”</strong> or <strong>“Services”</strong> under
          this Agreement. It is important to understand and acknowledge that the
          Blaze Platform is only a platform service provider and is neither a
          content creator nor an educational institution. While through
          technological means, Blaze provides the Platform that facilitates
          interaction between Creators and Followers; Blaze is not, in any
          manner, responsible for the interactions between Creators and
          Followers. Accordingly, Blaze is not liable for any disputes, claims,
          losses, injuries, or damage of any kind that may arise out of
          Creator-Follower relationship, including without limitation to, any
          Follower's dependency on any information provided by a Creator through
          any of the Blaze Services including without limitation their Content.{" "}
          <br />
          The terms and conditions governing Your use of the Platform and the
          Services offered therein are detailed hereunder.
          <br />
          <br />
        </p>
        <h3 className="font-extrabold text-2xl text-white mt-10 mb-4">
          Table of contents
        </h3>
        <ul className="space-y-2 capitalize list-none">
          <li onClick={() => scrollToSubheading("A")}>
            <a className="font-semibold text-[#428bca] hover:text-blue-700">
              A. Right to Access and Registration
            </a>
          </li>
          <li>
            <a
              onClick={() => scrollToSubheading("B")}
              className="font-semibold text-[#428bca] hover:text-blue-700"
            >
              B. Your Account
            </a>
          </li>
          <li>
            <a
              onClick={() => scrollToSubheading("C")}
              className="font-semibold text-[#428bca] hover:text-blue-700"
            >
              C. License to Creators
            </a>
          </li>
          <li>
            <a
              onClick={() => scrollToSubheading("D")}
              className="font-semibold text-[#428bca] hover:text-blue-700"
            >
              D. License to Followers
            </a>
          </li>
          <li>
            <a
              onClick={() => scrollToSubheading("E")}
              className="font-semibold text-[#428bca] hover:text-blue-700"
            >
              E. Communications on the Platform:{" "}
            </a>
          </li>
          <li>
            <a
              onClick={() => scrollToSubheading("F")}
              className="font-semibold text-[#428bca] hover:text-blue-700"
            >
              F. Processing of Personal Information by Creators
            </a>
          </li>
          <li>
            <a
              onClick={() => scrollToSubheading("G")}
              className="font-semibold text-[#428bca] hover:text-blue-700"
            >
              G. Access to the Platform
            </a>
          </li>
          <li>
            <a
              onClick={() => scrollToSubheading("H")}
              className="font-semibold text-[#428bca] hover:text-blue-700"
            >
              H. Code of Conduct
            </a>
          </li>
          <li>
            <a
              onClick={() => scrollToSubheading("I")}
              className="font-semibold text-[#428bca] hover:text-blue-700"
            >
              I. Intellectual Property
            </a>
          </li>
          <li>
            <a
              onClick={() => scrollToSubheading("J")}
              className="font-semibold text-[#428bca] hover:text-blue-700"
            >
              J. Your Content on the Platform
            </a>
          </li>
          <li>
            <a
              onClick={() => scrollToSubheading("K")}
              className="font-semibold text-[#428bca] hover:text-blue-700"
            >
              K. Feedback
            </a>
          </li>
          <li>
            <a
              onClick={() => scrollToSubheading("L")}
              className="font-semibold text-[#428bca] hover:text-blue-700"
            >
              L. Blaze’s Rights
            </a>
          </li>
          <li>
            <a
              onClick={() => scrollToSubheading("M")}
              className="font-semibold text-[#428bca] hover:text-blue-700"
            >
              M. Copyright Complaints and Takedown Policy
            </a>
          </li>
          <li>
            <a
              onClick={() => scrollToSubheading("N")}
              className="font-semibold capitalize text-[#428bca] hover:text-blue-700"
            >
              N. Trademarks
            </a>
          </li>
          <li>
            <a
              onClick={() => scrollToSubheading("O")}
              className="font-semibold capitalize text-[#428bca] hover:text-blue-700"
            >
              O. Claims Against User Content:
            </a>
          </li>
          <li>
            <a
              onClick={() => scrollToSubheading("P")}
              className="font-semibold capitalize text-[#428bca] hover:text-blue-700"
            >
              P. Pricing, Payments and Refunds
            </a>
          </li>
          <li>
            <a
              onClick={() => scrollToSubheading("Q")}
              className="font-semibold capitalize text-[#428bca] hover:text-blue-700"
            >
              Q. Deletion of Account
            </a>
          </li>
          <li>
            <a
              onClick={() => scrollToSubheading("R")}
              className="font-semibold capitalize text-[#428bca] hover:text-blue-700"
            >
              R. Disclaimer
            </a>
          </li>
          <li>
            <a
              onClick={() => scrollToSubheading("S")}
              className="font-semibold capitalize text-[#428bca] hover:text-blue-700"
            >
              S. Limitation of Liability
            </a>
          </li>
          <li>
            <a
              onClick={() => scrollToSubheading("T")}
              className="font-semibold capitalize text-[#428bca] hover:text-blue-700"
            >
              T. Indemnity and Release
            </a>
          </li>
          <li>
            <a
              onClick={() => scrollToSubheading("V")}
              className="font-semibold capitalize text-[#428bca] hover:text-blue-700"
            >
              V. OFAC
            </a>
          </li>
          <li>
            <a
              onClick={() => scrollToSubheading("W")}
              className="font-semibold capitalize text-[#428bca] hover:text-blue-700"
            >
              W. Other Communications
            </a>
          </li>
          <li>
            <a
              onClick={() => scrollToSubheading("X")}
              className="font-semibold capitalize text-[#428bca] hover:text-blue-700"
            >
              X. Governing law and Jurisdiction
            </a>
          </li>
          <li>
            <a
              onClick={() => scrollToSubheading("Y")}
              className="font-semibold capitalize text-[#428bca] hover:text-blue-700"
            >
              Y. Miscellaneous
            </a>
          </li>
          <li>
            <a
              onClick={() => scrollToSubheading("Z")}
              className="font-semibold capitalize text-[#428bca] hover:text-blue-700"
            >
              Z. Contact Us
            </a>
          </li>
        </ul>
        <br />

        <div
          id="A"
          className="text-justify box-border font-medium text-white text-base mt-5 mb-2.5"
          ref={subheadingRefs.A}
        >
          <h2 className=" text-white font-extrabold mt-10 text-lg">
            A. Right to Access and Registration
          </h2>
          <div className="text-justify text-white box-border">
            To access the Platform and use the Services provided therein, You
            will be required to register and create an account and provide
            certain details or other information as outlined in our Privacy
            Policy <strong>(the “Registration”)</strong>. You can either
            register as an individual or as an entity. If You’re an individual
            You must be at least 18 (eighteen) years of age, or, if You are
            between the ages of 13 and 18, You must have Your parent or legal
            guardian's permission to use the Platform. By using the Blaze
            Platform, You are, through Your actions, representing and warranting
            to us that You have obtained the appropriate consents/permissions to
            use the Platform.{" "}
            <u>
              <strong className="underline">
                If You are under the age of 13 years, You may neither use our
                Platform in any manner nor may You register for an account.
              </strong>
            </u>{" "}
            You hereby also authorize Blaze to use the data, information
            provided by you to compile its own data, profile or for analysis or
            use for their own purpose.This restriction is in compliance with the
            Children’s Online Privacy Protection Act (COPPA) in the U.S., which
            prohibits the collection of personal information from children under
            13 without parental consent.
            <br />
            <br />
            If You’re a Creator/user, we require You to provide the basic
            personal information such as Your name, email address, mobile
            number, create a username and password for Your account, and a
            simple description of Yourself; we also give You an option to upload
            Your photo and include links of Your social media &amp; YouTube
            channels, websites, blogs, etc., which could help You build Your
            brand better on the Platform. If You’re a Follower, we require You
            to only provide Your name. mobile number, instagram username and
            email address. Please read our{" "}
            <u>
              <strong>
                <NavLink
                  end
                  to={"/PrivacyPolicy"}
                  className="text-[#428bca] hover:text-blue-700"
                >
                  Privacy Policy
                </NavLink>
              </strong>
            </u>{" "}
            to understand how we handle Your information. You represent that the
            information indicated by You in the Registration is true and
            complete, and that You meet the eligibility requirements for use of
            the Platform, and You agree to update the Registration upon any
            changes to the information. <br />
            <br />
            The registration in itself is free, however, products/services
            offered therein may be chargeable. Notwithstanding the foregoing, by
            registering Yourself on the Platform, You are not obligated to
            purchase/use any product or service offered therein.
          </div>
        </div>

        <div
          id="B"
          className="text-justify box-border font-medium text-white mt-5 mb-2.5"
          ref={subheadingRefs.B}
        >
          <h2 className=" text-white font-extrabold mt-10 text-lg">
            B. Your Account
          </h2>
          <div className="text-justify text-white box-border text-base">
            As a User, once You’ve successfully registered on the Platform, an
            account gets created based on the information provided by You at the
            time of Your Registration on the Platform.
            <br />
            <br /> You are responsible for maintaining the confidentiality of
            Your account and password to access the Platform. You acknowledge
            that Your account is personal to You and agree not to provide any
            other person with access to the Platform and to restrict access to
            Your device to prevent any unauthorized access to Your account. You
            agree to accept responsibility for all activities that occur under
            Your account. You should use particular caution when accessing Your
            account from a public or shared computer so that others are not able
            to view or record Your username, password, or other personal
            information. You should take all necessary steps to ensure that the
            password is kept confidential and secure at all times, and if You
            have any reason to believe that Your password has become known to
            anyone else, or if the password is being, or is likely to be, used
            in an unauthorized manner, You agree to immediately change Your
            password or inform us of any unauthorized access to or use of Your
            username or password, so that we are able to help You stop or
            prevent such unauthorized access. Please ensure that the details You
            provide us are correct and complete and inform us immediately of any
            changes to the information that You provided when registering
            through the platform.
            <br />
            <br /> We maintain and reserve the right to refuse access to the
            Platform, terminate accounts, remove content at any time without any
            notice to You. We further maintain the right to disable any
            username, password, or other identifier, whether chosen by You or
            provided by us, at any time if, in our opinion, You have violated
            any provision of this Agreement or any law of the land. <br />
            <br />
            Further, to access the Platform, create, publish and/or view the
            Content on the Platform, You will need to use a
            “Supported/Compatible Device” which means a personal computer,
            mobile phone, portable media player, or other electronic device that
            meets the system and compatibility requirements and on which You are
            authorized to operate the Platform. The Supported/Compatible Devices
            to access the Platform may change from time to time and, in some
            cases, whether a device is (or remains) a Supported/Compatible
            Device may depend on software or systems provided or maintained by
            the device manufacturer or other third parties. Accordingly, devices
            that are Supported/Compatible Devices at one time may cease to be
            Supported/Compatible Devices in the future. Thus, kindly make sure
            that the device that You use is compatible with our system/software
            to use the Platform and avail the services offered therein.
          </div>
        </div>

        <div
          id="C"
          className="text-justify box-border font-medium text-white mt-5 mb-2.5"
          ref={subheadingRefs.C}
        >
          <h2 className=" text-white font-extrabold mt-10 text-lg">
            C. License to Creators
          </h2>
          <div className="text-justify text-white box-border text-base">
            Blaze grants You (as a Creator) a limited, non-exclusive,
            non-transferable, non-sub-licensable license to access and use the
            Platform for Your own personal and commercial use in accordance with
            the terms of this Agreement. Except as expressly permitted under
            this Agreement or otherwise in writing, You will not reproduce,
            duplicate, copy, sell, redistribute, create derivative works or
            otherwise exploit this Platform or any portion of this Platform
            (including but not limited to any copyrighted material, trademarks,
            or other proprietary information).
          </div>
        </div>

        <div
          id="D"
          className="text-justify box-border font-medium text-white mt-5 mb-2.5"
          ref={subheadingRefs.D}
        >
          <h2 className=" text-white font-extrabold mt-10 text-lg">
            D. License to Followers
          </h2>
          <div className="text-justify text-white box-border text-base">
            Blaze grants You, as a Follower, a limited, non-exclusive license to
            access and use the Platform for Your own personal use. This license
            does not grant You the right to assign or sublicense the license
            granted under this Agreement to anyone else. Except as expressly
            permitted by Blaze under this Agreement or otherwise in writing, You
            will not reproduce, duplicate, copy, sell, resell, redistribute,
            create derivative works or otherwise exploit this Platform or any
            portion of this Platform (including but not limited to any
            copyrighted material, trademarks, or other proprietary information).
          </div>
        </div>

        <div
          id="E"
          className="text-justify box-border font-medium text-white mt-5 mb-2.5"
          ref={subheadingRefs.E}
        >
          <h2 className=" text-white font-extrabold mt-10 text-lg">
            E. Communications on the Platform:{" "}
          </h2>
          <div className="text-justify text-white box-border text-base">
            You understand and agree that the Platform enables You (as a
            Creator) to make available Your Content for consumption by
            Followers; similarly, as a Follower, the Platform enables You to
            consume the Content made available by Creators on the Platform. You
            further agree and acknowledge that the Company is only a facilitator
            and is not and cannot be a party to or control in any manner any
            transactions on the Platform. Accordingly, the contract of sale of
            Content/products on the Platform shall be a strictly bipartite
            contract between the Creators and Followers on the Platform. <br />
            <br /> As provided in our Privacy Policy[3], Blaze does not provide,
            sell, rent, release, disclose, or otherwise transfer any data
            provided You on the Platform, in any manner whatsoever, for monetary
            or other valuable consideration. Nevertheless, we clarify that
            Followers assume full responsibility for the disclosure and use of
            any other personal information the Follower chooses to disclose to
            any Creator on the Platform.
            <br /> Further, the Platform includes provision and facilitation of
            Services designed to enable You to communicate with Creators and
            other Followers. Blaze has no obligation to monitor these Services,
            but if Blaze receives a complaint from any User of misconduct in any
            form on such Services or if Blaze otherwise deems necessary, Blaze
            may terminate or suspend Your access to any Services at any time,
            without notice, for any reason. You acknowledge and accept that any
            User content (including without limitation chats, postings, or
            materials posted by Users) on the Platform is neither endorsed nor
            controlled by us. You further understand and agree that You, not
            Blaze, shall be solely responsible for such content including its
            legality, reliability, accuracy, and appropriateness, and the
            consequences of its publication. You represent and warrant that You
            own and control all rights in and to any content uploaded by You to
            the Platform, including the Subscriptions, or are licensed to use
            and reproduce such content on the Platform. Blaze will not under any
            circumstances be liable for any activity within Platform. Blaze is
            not responsible for information that You choose to share on the
            Platform, or for the actions of other Users. As a condition of Your
            use of the Services, and without limiting Your other obligations
            under this Agreement, You agree to comply with the restrictions and
            terms of use set forth in this Agreement.
            <br />
            <br />
            Having said all of the above, all Your information including
            discussions on the Services are encrypted and such information is
            accessible only in accordance with the terms of our Privacy Policy.
          </div>
        </div>

        <div
          id="F"
          className="text-justify box-border font-medium text-white mt-5 mb-2.5"
          ref={subheadingRefs.F}
        >
          <h2 className=" text-white font-extrabold mt-10 text-lg">
            F. Processing of Personal Information by Creators
          </h2>
          <div className="text-justify text-white box-border text-base">
            Blaze's Data Processing Agreement ('DPA') forms an integral part of
            this Agreement and details Creators’ and Blaze’s obligations with
            respect to the processing of personal information. Creators are
            responsible for protecting all personal information that they
            receive from, or provide to, Blaze in connection with their use of
            the Platform or any of the Services and agree to comply with the
            terms of the DPA to the extent applicable. Creators are considered
            controllers or 'owners' of the personal information they collect
            from and about Followers. In addition to agreeing to the terms of
            the DPA, Creators are also responsible for providing an appropriate
            privacy notice to their Followers, respecting their Followers'
            privacy rights in accordance with applicable law, and providing
            access to or deleting Followers' personal information if they
            request and as required by applicable law. Creators must comply with
            relevant U.S. data protection regulations, including the California
            Consumer Privacy Act (CCPA), when processing personal information of
            users based in the U.S.
          </div>
        </div>

        <div
          id="G"
          className="text-justify box-border font-medium text-white mt-5 mb-2.5"
          ref={subheadingRefs.G}
        >
          <h2 className=" text-white font-extrabold mt-10 text-lg">
            G. Access to the Platform
          </h2>
          <div className="text-justify text-white box-border text-base">
            We will do our utmost to ensure that availability of the Platform
            will be uninterrupted and that transmissions will be error-free.
            However, due to the nature of the Internet, the same cannot be
            guaranteed. Also, Your access to the Platform may occasionally be
            suspended or restricted to allow for repairs, maintenance, or the
            introduction of new facilities or services at any time without prior
            notice. We will attempt to limit the frequency and duration of any
            such suspension or restriction. You agree that Blaze will not be
            liable if for any reason all or part of the Platform is unavailable
            at any time or for any period.{" "}
          </div>
        </div>

        <div
          id="H"
          className="text-justify box-border font-medium text-white mt-5 mb-2.5"
          ref={subheadingRefs.H}
        >
          <h2 className=" text-white font-extrabold mt-10 text-lg">
            H. Code of Conduct
          </h2>
          <div className="text-justify text-white box-border text-base">
            To ensure all Users on the Platform have a pleasant experience, You
            agree to the following: <br />
            <br /> <strong>Legit usage of the Platform:</strong> You agree to
            use the Platform only for lawful purposes and You are not allowed to
            use our Platform to engage in any kind of activity that violates any
            applicable central, state, local, federal or international law or
            regulation (including, without limitation, any laws regarding the
            export of data or software to and from the USA or other countries).
            Also, You agree that You will not use the Platform in any manner
            that would disrupt, damage or impair the Platform or access to it,
            in any manner, including promoting or encouraging illegal activity
            such as hacking, cracking or distribution of counterfeit software,
            compromised accounts, or cheats or hacks for the Platform and
            conduct of any form of fraudulent activity. <br />
            <br /> <strong>No harmful or dangerous content:</strong> We believe
            that our Platform is a safe space for Followers and Creators alike
            and would like Your help in ensuring that it remains so. Keeping
            this in mind, any content which incites or promotes violence, that
            may cause physical or emotional harm or that may endanger the safety
            of any individual or is otherwise objectionable is expressly
            prohibited on the Platform. The sale and promotion of any regulated
            or illegal goods is not allowed. The Platform is to be used only for
            the permitted uses as detailed under this Agreement.
            <br />
            <br /> <strong> No hateful or defamatory content:</strong> We
            realise that there may be instances when there are exchange of ideas
            and opinions which is essential in the learning process, while we
            agree that individuals have the right to voice their opinion, we do
            not encourage or tolerate any form of hate speech, article,
            photographs or statements that are libelous, slanderous,
            threatening, violent, predatory, defamatory, or any statement that
            incites hatred against specific individuals or groups with respect
            to but not limited to race or ethnic origin, country caste,
            religion, disability, gender, age, sexual orientation/gender
            identity etc. <br />
            <br /> <strong>Violent and graphic content:</strong> Any content
            whose sole objective is to sensationalise, shock or disturb
            individuals is not allowed. We do not allow any content that
            promotes terrorist acts or incites violence, to be uploaded on the
            Platform in any manner. <br />
            <br /> <strong>Harassment and bullying:</strong> Blaze Platform will
            be used by many Users on a daily basis and it is important to be
            respectful and kind to your fellow Users. We do not tolerate any
            form of harassment or bullying on the Platform and strive to keep
            the Platform a safe space to foster learning. Harassment in this
            case would include, without limitation, to abusive videos, comments,
            messages, revealing someone’s personal information, including
            sensitive personally identifiable information of individuals,
            content or comments uploaded in order to humiliate someone,
            stalking, sexual harassment or sexual bullying in any form.
            <br />
            <br /> <strong> Spam:</strong> Posting untargeted, unwanted and
            repetitive content, comments or messages with an intention to spam
            the Platform and to drive traffic from the Platform to other
            third-party sites is in direct violation of this Agreement. Posting
            links to external websites with malware and other prohibited sites
            is not allowed.
            <br />
            <br /> <strong>Scams:</strong> Any content uploaded or posted in
            order to trick others for their own financial gain is not allowed
            and we do not tolerate any practices of extortion or blackmail,
            either.
            <br />
            <br /> <strong>Privacy violation:</strong> Kindly refer to our
            Privacy Policy [4] to know how to protect Your privacy and respect
            the privacy of other Users. <br />
            <br /> <strong>Impersonation:</strong> Impersonating another person,
            including but not limited to, Blaze, a Blaze employee, or another
            User, is not permitted while using our Platform. In this case
            impersonation would mean the intention to cause confusion regarding
            who the real person is by pretending to be them (such as using
            names, image, documents, certificates etc. not belonging to You or
            not used to identify You, or pretending to be a company, institute,
            group etc., by using their logo, brand name or any distinguishing
            mark). <br />
            <br />{" "}
            <strong>Unauthorized Access or Disabling of Platform: </strong>
            You agree not to (i) use the Platform in any manner that could
            disable, overburden, damage, or impair the Platform or interfere
            with any other User’s use of the Platform; (ii) not to use any
            manual process to monitor or copy any of the material on the
            Platform or for any other unauthorized purpose without Blaze’s prior
            express written consent; (iii) use any device, software, or routine
            that interferes with the proper working of the Platform; (iv) attack
            the Platform via a denial-of-service attack; (v) attempt to gain
            unauthorized access to, interfere with, or disrupt any parts of the
            Platform, the server on which the Platform is stored, or any server,
            computer, or database connected to or associated with the Platform;
            and (vi) introduce any viruses, trojan horses, worms, keystroke
            loggers, malware, or other material which is malicious or
            technologically harmful to the Platform. <br />
            <br /> We are not obligated to monitor any content published on the
            Platform or review the conduct occurring on or through the Platform,
            including the interactions between the Creators and Followers,
            however, if any violation of the above rules of conduct comes to our
            notice, then, we reserve the right to refuse Your access to the
            Platform, terminate accounts or remove such violating content at any
            time without notice to You.
          </div>
        </div>

        <div
          id="I"
          className="text-justify box-border font-medium text-white mt-5 mb-2.5"
          ref={subheadingRefs.I}
        >
          <h2 className=" text-white font-extrabold mt-10 text-lg">
            I. Intellectual Property
          </h2>
          <div className="text-justify text-white box-border text-base">
            {" "}
            <strong>Blaze Content:</strong> Blaze owns all information and
            materials (in whatever form or media) provided or communicated to
            You by or on behalf of Blaze including but not limited to, the
            Platform, illustrations, letters, images, ideas, concepts, the
            layout, design, flow, look and feel of the Platform, logos, marks,
            graphics, audio files, video files, any software which is owned by
            or licensed to Blaze, the underlying source and object code,
            instructions embedded in any form of digital documents and other
            data, information, or material made available to You by Blaze{" "}
            <strong>("Blaze Content”)</strong>. Blaze Content, including its
            trademarks, may not be modified by You in any way. You acknowledge
            and agree that You do not acquire any ownership rights to the Blaze
            Content or the Platform by use of the Platform. You acknowledge and
            agree that the Blaze Content is protected by copyright, trademark,
            patent, trade secret and other intellectual property or proprietary
            rights laws and any unauthorize use, reproduction, modification,
            distribution, transmission, republication, display or performance of
            the Blaze Content and any component thereof is strictly prohibited.{" "}
            <br />
            <br /> You may not utilize any data mining tools, robots, or similar
            data gathering and extraction tools to extract (whether once or many
            times) for re-utilization of any substantial parts of this Platform,
            without the Blaze's express written consent. <br />
            <br /> <strong>Your Content:</strong> You shall remain the sole
            owner of the Content uploaded by You on the Platform and Blaze does
            not claim any ownership over any content or material uploaded by You
            on the Platform using the Services (“Your Content”). However, Blaze
            reserves the right to retain copies of all such material uploaded by
            you for its research and data compilation.
          </div>
        </div>

        <div
          id="J"
          className="text-justify box-border font-medium text-white mt-5 mb-2.5"
          ref={subheadingRefs.J}
        >
          <h2 className=" text-white font-extrabold mt-10 text-lg">
            J. Your Content on the Platform
          </h2>
          <div className="text-justify text-white box-border text-base">
            {" "}
            By uploading Your Content on the Platform, You agree that:
            <br />
            <br />
            You are solely responsible for Your Content; and that You own or
            otherwise control all of the rights to Your Content; and that Your
            Content does not infringe upon another’s intellectual property
            rights and You have procured all necessary rights and licenses from
            third parties who may own Your content or any part thereof.
            <br />
            <br />
            Your Content made available on the Platform, is lawful.
            <br />
            <br />
            Your Content does not breach any applicable policy or guideline of
            Blaze including this Agreement; You hereby acknowledge that you have
            read, understood and accepted all of Blaze’s policies and
            guidelines.
            <br />
            <br />
            You and/or Your Content will not cause any sort of distress or
            injury to any person or entity (including that the content or
            material is not defamatory).
            <br />
            <br /> You have provided Your Content on and to the Platform at Your
            sole discretion and Blaze does not have a way to certify or approve
            the uploading of Your Content on the Platform.
            <br />
            <br />
            In furtherance of the Blaze Services, You grant Blaze a
            non-exclusive, royalty-free, transferable and sublicensable rights
            to use, copy, distribute, retain and transmit Your Content as well
            as technical information collected via the Platform to the extent
            necessary to (i) provide the Platform and our services; (ii) perform
            our obligations under this Agreement; (iii) provide, monitor,
            correct, and improve the Platform and services related thereto; (iv)
            de-identify User data such that there is no reasonable basis to
            believe that the information can be used, alone, or in combination
            with other reasonably available information, to identify any
            individual or to identify User as the source of such data; (v)
            aggregate User data with other data; and (vi) comply with applicable
            laws.{" "}
          </div>
        </div>

        <div
          id="K"
          className="text-justify box-border font-medium text-white mt-5 mb-2.5"
          ref={subheadingRefs.K}
        >
          <h2 className=" text-white font-extrabold mt-10 text-base">
            K. Feedback
          </h2>
          <div className="text-justify text-white box-border text-base">
            If Creators or their Followers submit suggestions, ideas, comments,
            or questions containing product feedback about or posted through the
            Platform <strong>(“Feedback”)</strong>, such Creator and such
            Follower grants Blaze and its affiliates a worldwide, non-exclusive,
            royalty-free, perpetual, and irrevocable right to use (and full
            right to sublicense), reproduce, modify, adapt, publish, translate,
            create derivative works from, distribute, transmit, and display such
            Feedback in any form. Creators and Followers shall have no
            intellectual property right in the Platform as a result of Blaze’s
            incorporation of Feedback into the Platform.ited to any copyrighted
            material, trademarks, or other proprietary information).
          </div>
        </div>

        <div
          id="L"
          className="text-justify box-border font-medium text-white mt-5 mb-2.5"
          ref={subheadingRefs.L}
        >
          <h2 className=" text-white font-extrabold mt-10 text-lg">
            L. Blaze’s Rights
          </h2>
          <div className="text-justify text-white box-border text-base">
            In respect of the entire Platform, Blaze reserves the following
            rights:
            <br />
            <br /> Blaze reserves the right to remove You and/or Your Content
            without notice if You violate any of the provisions of this
            Agreement.
            <br /> Blaze may modify, terminate, or refuse to provide Services at
            any time for any reason, without notice.
            <br /> Notwithstanding anything contrary stated in this Agreement,
            in its sole discretion, Blaze may remove anyone from the Platform at
            any time for any reason. Blaze reserves the right to access Your
            account and/or Your Content in order to respond to requests for
            technical support, to maintain the safety and security of the
            Platform, and for other legitimate business purposes, as necessary,
            in Blaze’s discretion.
            <br />
            Blaze may, but has no obligation to, monitor any content that
            appears on the Platform or review any conduct occurring through the
            Platform, including any interactions between Creators, Followers and
            Blaze employees.
            <br /> If You close Your account, Blaze may keep a copy of Your
            Content after termination for the limited purpose of archiving the
            same for compliance with any relevant laws and regulations and for
            our internal business purposes, subject to applicable law. For the
            limited purpose stated herein, You grant us a non-exclusive,
            perpetual, irrevocable license to retain a copy of Your Content.
            <br />
            In its sole discretion, Blaze has an absolute right to modify,
            change, alter, suspend, or terminate any provision of this Agreement
            without notice at any time. Your continued use of the Platform or
            any of the Services, constitutes your acceptance of such change(s).
          </div>
        </div>

        <div
          id="M"
          className="text-justify box-border font-medium text-white mt-5 mb-2.5"
          ref={subheadingRefs.M}
        >
          <h2 className=" text-white font-extrabold mt-10 text-lg">
            M. Copyright Complaints and Takedown Policy
          </h2>
          <div className="text-justify text-white box-border text-base">
            <u>Copyright Complaints</u>: Blaze respects the intellectual
            property of others, and we ask our users to do the same. If You
            believe that Your work has been copied in a way that constitutes
            copyright infringement, or that Your intellectual property rights
            have been otherwise violated, You should notify Blaze of your
            infringement claim in accordance with the procedure set forth below.
            Blaze will process and investigate notices of alleged infringement
            and will take appropriate actions under the Digital Millennium
            Copyright Act (see 17 U.S.C. § 512(c)(3)) (“DMCA”) and other
            applicable intellectual property laws with respect to any alleged or
            actual infringement. A notification of claimed copyright
            infringement should be emailed to the Blaze’s Copyright Agent at
            connect@Blazeapp.com (Subject line: “DMCA Takedown Request”). To be
            effective, the notification must be in writing and contain the
            following information: <br />
            <br />
            <ul>
              <li>
                {" "}
                ● an electronic or physical signature of You or the person
                authorized to act on behalf of the owner of the copyright or
                other intellectual property interest;{" "}
              </li>
              <li>
                ● a description of the copyrighted work or other intellectual
                property that You claim has been infringed;
              </li>
              <li>
                ● a description of where the material that You claim is
                infringing is located on the Platform, with enough detail that
                we may find it on the Platform;
              </li>
              <li>Your address, telephone number, and email address;</li>
              <li>
                ● a statement by You that You have a good faith belief that the
                disputed use is not authorized by the copyright or intellectual
                property owner, its agent, or the law;
              </li>
              <li>
                ● a statement by You, made under penalty of perjury, that the
                above information in Your Notice is accurate and that You are
                the copyright or intellectual property owner or authorized to
                act on the copyright or intellectual property owner’s behalf.
              </li>
              <br />
              <br />
              If You submit a notice of infringement that knowingly materially
              misrepresents that any Content, information, or communication on
              the Platform is infringing upon a copyright, You may be held
              liable for damages and attorneys’ fees.
              <br />
              <br />
              <u>Counter-Notice:</u> If You believe that Your Content that was
              removed (or to which access was disabled) is not infringing, or
              that You have the authorization from the copyright owner, the
              copyright owner’s agent, or pursuant to the law, to upload and use
              the content in Your Content, You may send a written counter-notice
              containing the following information to the Copyright Agent using
              any of the methods mentioned under ‘Copyrights Complaint’ section
              above:
              <br />
              <br />● Your physical or electronic signature;
              <br />● identification of the content that has been removed or to
              which access has been disabled and the location at which the
              content appeared before it was removed or disabled;
              <br />● a statement that You have a good faith belief that the
              content was removed or disabled as a result of mistake or a
              misidentification of the content; and
              <br />● Your name, address, telephone number, and email address, a
              statement that You consent to the jurisdiction of the court
              located within Bangalore, Karnataka and a statement that You will
              accept service of process from the person who provided
              notification of the alleged infringement.
              <br />
              <br />
              If a counter-notice is received by the Copyright Agent, the
              Company will send a copy of the counter-notice to the original
              complaining party informing that person, that it may replace the
              removed content or cease disabling it in 10 (ten) business days.
              Unless the copyright owner files an action seeking a court order
              against the content provider, member or user, the removed content
              may be replaced, or access to it restored, in 10 (ten) to 14
              (fourteen) business days or more after receipt of the
              counter-notice, at our sole discretion.
              <br />
              <br /> <u>Repeat Infringer Policy:</u> In accordance with the DMCA
              and other applicable laws, Blaze has adopted a policy of
              terminating users who are deemed to be repeat infringers, in
              appropriate circumstances and at Blaze’s sole discretion. Blaze
              may also at its sole discretion, limit access to the Platform
              and/or terminate the accounts of any users who infringes any
              intellectual property rights of others, whether or not there is
              any repeat infringement.
            </ul>
          </div>
        </div>

        <div
          id="N"
          className="text-justify box-border font-medium text-white mt-5 mb-2.5"
          ref={subheadingRefs.N}
        >
          <h2 className=" text-white font-extrabold mt-10 text-lg">
            N. Trademarks
          </h2>
          <div className="text-justify text-white box-border text-base">
            Blaze, Blaze LOGO, and other marks indicated on our Platform are
            trademarks or registered trademarks of the Company. Platform's
            graphics, logos, page headers, button icons, scripts and service
            names are the trademarks or trade dress of the Platform. Blaze’s
            trademarks and trade dress may not be used in connection with any
            product or service that is not Blaze’s, in any manner that is likely
            to cause confusion among customers, or in any manner that disparages
            or discredits Blaze. All other trademarks not owned by Blaze that
            appear on this Platform are the property of their respective owners,
            who may or may not be affiliated with, connected to, or sponsored by
            Blaze.
            <br />
            <br /> At Blaze, we respect the intellectual property of others just
            as much ours and hence, if You believe that Your intellectual
            property rights (other than copyrights) have been used in a way that
            gives rise to concerns of infringement, then kindly write to us at
            connect@Blazeapp.com with complete details. (For copyright
            complaints, please refer to{" "}
            <u>‘Copyright Complaints and Takedown Policy’</u> section above).
            Further, we reserve the right to remove access to content that we
            know, or have reason to know, violates the intellectual property
            rights of Blaze or other users. Please note that we may send a copy
            of any infringement claim received to the user who posted the
            content that was reported by You as infringing.
          </div>
        </div>

        <div
          id="O"
          className="text-justify box-border font-medium text-white mt-5 mb-2.5"
          ref={subheadingRefs.O}
        >
          <h2 className=" text-white font-extrabold mt-10 text-lg">
            O. Claims Against User Content:
          </h2>
          <div className="text-justify text-white box-border text-base">
            Through the Platform, You will have the ability to access content,
            links to websites, and services provided by Creators, Followers and
            other users. Your use of any content and/or material provided on
            this Platform or a third-party website is at Your own risk. Blaze
            does not monitor or have any control over or does not warrant, and
            makes no claim or representation regarding the accuracy,
            completeness, or usefulness of any content/material provided on the
            Platform by its users and accepts no responsibility for reviewing
            changes or updates to, or the quality, content, policies, nature or
            reliability of, such content and/or material. Blaze disclaims all
            liability and responsibility arising from any reliance placed on
            such materials by You. All statements and/or opinions expressed in
            these materials, and all articles and responses to questions and
            other content, other than the Blaze Content, are solely the
            responsibility of the person or entity providing those materials.
            You should review applicable terms and policies, including privacy
            and data gathering practices, of any third party, and should make
            whatever investigation You feel necessary or appropriate before
            proceeding with any transaction with any third party.
          </div>
        </div>

        <div
          id="P"
          className="text-justify box-border font-medium text-white mt-5 mb-2.5"
          ref={subheadingRefs.P}
        >
          <h2 className=" text-white font-extrabold mt-10 text-lg">
            P. Coins, Digital Items
          </h2>
          <div className="text-justify text-white box-border text-base">
            The Services provide a feature where the money deposited by Users is
            held as "coins" ("Coins"). These Coins are exclusively intended for
            use within our Services, including participation in various
            features, gifting virtual items, and interacting with Creators
            (collectively known as "Digital Items").
            <br />
            <br />
            You acknowledge and agree that regardless of the terminology used,
            any amounts transferred for purchasing Coins and Digital Items are
            held as a deposit on your behalf by a third-party payment provider
            in escrow until you gift them to a Creator, if applicable. At the
            point of gifting, the deduction from your Coins balance signifies a
            payment made by you, as outlined in this provision.
            <br />
            <br />
            Your use of any Coins and Digital Items must be solely for
            legitimate purposes within the Services and must consistently comply
            with these Terms and all applicable laws. Digital Items hold no
            real-world monetary value and cannot be redeemed or cashed out for
            "real world", money, goods, or any other item of monetary value from
            Blaze or any other party. You understand that you have no right or
            title in the Digital Items appearing or originating in the Services,
            whether "awarded" or "purchased" from Blaze.
            <br />
            <br /> Purchases of Digital Items are final and non-refundable,
            except at Blaze's sole discretion. You are prohibited from
            transferring, selling, or exchanging Digital Items and Coins outside
            the Services, or attempting to sell, give, or trade them in the real
            world. Any violation of these Terms may result in account
            termination and legal action. Any balance of Coins in your Blaze
            account does not represent real-world monetary value.
            <br />
            <br /> By using the Service, you agree to pay for all Digital Items
            purchased and authorize Blaze to charge your payment method. You are
            responsible for providing a valid payment method and for any
            applicable charges, including taxes and fees, related to your
            deposit. Furthermore, when you make a deposit or use any other
            payable features, you acknowledge and agree that you shall pay any
            applicable charges, including taxes and any fees (e.g., processing
            fees), related to your transaction.
            <br />
            <br /> Digital Items obtained on application stores or platforms,
            including but not limited to Apple iOS or Android, will be subject
            to the payment terms and conditions of those platforms. Blaze does
            not have control over the payment methods available on those
            platforms and shall not be held liable for payment processing by
            third parties. Please refer to the terms of service of those
            platforms for further information.
            <br />
            <br />
            You may only give Digital Items in accordance with these Terms and
            via designated features within the Services. Once given, Digital
            Items cannot be refunded, undone, or withdrawn. Attempting to
            transfer or sell Digital Items in violation of these Terms may
            result in account termination and legal action.
            <br />
            <br />
            Giving Digital Items to Creators may result in income for them, and
            Creators are solely responsible for paying any taxes on their
            earnings. Completed transactions are confirmed upon receipt of
            payment, and Blaze may cancel incomplete transactions at its
            discretion. Blaze facilitates the collection and transmission of
            Digital Items to Creators but does not act as their agent in
            procuring or providing them. Blaze retains no authority to contract
            on behalf of Users or Creators regarding the giving of Digital
            Items.
            <br />
            <br />
            The ratio of real currency to Coins and Digital Items is determined
            by Blaze, and Blaze may modify this ratio at any time without
            notice. Your use of the Services constitutes acceptance of the
            current ratio at the time of deposit. Blaze may update its terms of
            sale for Coins and Digital Items, and you agree to review these
            terms before making any deposits.
            <br />
            <br />
            Users, Creators, and everyone who uses our Services are strictly
            prohibited from engaging in self-gifting activities on our platform.
            Self-gifting refers to the act of a user sending, attempting to
            send, or assisting to send, Coins, Digital Items, or any form of
            benefit to themselves, either directly or indirectly. In the event
            we detect self- gifting behaviors through various monitoring tools,
            Blaze may at its sole discretion terminate your account, and
            withhold any funds or virtual items (including Digital Items and
            Diamonds) that are present therein.
            <br />
            <br />
            Blaze retains the authority to temporarily confiscate Coins from
            your Blaze account if it reasonably deems your account to be
            inactive. An account will be classified as dormant if it remains
            inactive for a period of sixty (60) days ("Dormant Account"). Any
            confiscated Coins will be restored to your account upon
            reactivation.
            <br />
            <br />
            If your Blaze account balance regarding Coins is inaccurate due to
            system maintenance or technical failures, Blaze will make
            commercially reasonable efforts to rectify the situation. Feel free
            to reach out to us regarding your Coin balance, purchases of Digital
            Items, or gifting Digital Items at support@blazeapp.live.
          </div>
        </div>

        <div
          id="Q"
          className="text-justify box-border font-medium text-white mt-5 mb-2.5"
          ref={subheadingRefs.Q}
        >
          <h2 className=" text-white font-extrabold mt-10 text-lg">
            Q. Diamonds Program
          </h2>
          <div className="text-justify text-white box-border text-base">
            Any Digital Gifts that are given to You are converted in Your
            account to, and are referred to herein as, “Diamonds”.
            Notwithstanding your receipt or possession of Diamonds, you
            acknowledge and agree that the amounts of any Diamonds in your Blaze
            account do not refer to any credit balance of real currency or its
            equivalent until withdrawn.
            <br />
            <br />
            Any giving of a Digital Gift by a User to a Creator is made solely
            in the User's discretion. Blaze merely facilitates the collection
            and transmission of the Digital Gift to the Creator and shall not be
            deemed as acting as an agent in procuring or providing the Digital
            Gifts. Blaze also retains no actual or apparent authority to
            contract on behalf of either User or Creator to effectuate the
            giving of Digital Gifts. The sole contractual rights arising as
            between the Creator and the User are granted in these Terms of Use
            and nothing in these Terms of Use shall be read to imply that a
            right or obligation is provided with respect to Blaze as to the
            giving of Digital Gifts.
            <br />
            <br />
            You acknowledge that the ratio of Digital Gift to Diamonds is
            detailed on Blaze's platform (as amended). You confirm your
            understanding and acceptance of the then-current ratio of Digital
            Gifts to Diamonds at the time of their redemption.
            <br />
            <br />
            Any virtual currency balance shown in your Blaze account does not
            constitute a real world balance or reflect any stored value. Due to
            the conversion, clearing, and transfer costs associated with any
            payment made to a Creator seeking to redeem their Diamonds
            ("Transfer Fees"), please note that in the event the Transfer Fees
            exceed your current Diamond balance, you will be charged for any
            discrepancies between the balance and the Transfer Fees.
            Furthermore, when you ask to redeem your Diamonds, you acknowledge
            and agree that you shall pay any applicable charges, including taxes
            and any fees related to your redemption, including, without
            limitation, platform fees and processing fees. You may not redeem
            more than 100,000 Diamonds (or such other amounts as may be
            determined from time to time by Blaze in accordance with its
            policies and procedures) per day. Blaze reserves the right to
            temporarily confiscate from your Blaze account any unredeemed
            Diamonds in the event that Blaze determines, in its sole discretion,
            that you have not logged in to your Blaze account during a period of
            60 days. Any confiscated Diamonds will be reinstated in your account
            on return. In addition, you will not be able to redeem Diamonds if
            your account has been suspended, limited or terminated by you or by
            Blaze.
            <br />
            <br />
            Blaze may, at its sole discretion, reject any attempted redemption
            and withhold any funds related to your Blaze account if it has a
            reason to believe that:
            <ul>
              <li>● you have breached the Terms of Use;</li>
              <li>● your account is related to fraudulent activity;</li>
              <li>
              ● your account or Content are related to illegal activities; or
              </li>
              <li>
              ● the electronic transfer of funds by the User to Blaze was not
                successfully completed for any reason.
              </li>
            </ul>
          <br />
          <br />
          In the event your Blaze account balance with respect to Diamonds is
          incorrect due to system maintenance or other technical failures as
          determined by Blaze in its sole discretion, Blaze will use
          commercially reasonable efforts to correct your Blaze account balance.
          You may contact us regarding your Blaze account balance at
          support@blazelive.app.
          <br />
          <br />
          You are solely responsible for paying all federal, state and other all
          type of taxes (direct or indirect taxes) related to your Diamonds,
          funds or withdrawal in accordance with all applicable laws. In case we
          are entitled to withhold any tax or duty on behalf of you; we may send
          you federal or state tax forms and/or other appropriate form(s).. In
          that case, we may withhold from you any amount required to be withheld
          by applicable laws, including amounts due in connection with your
          failure to complete relevant tax documentation.
          <br />
          <br />
          Blaze takes fraud seriously. Please note that in addition to taking
          any desirable or necessary legal action, Blaze reserves the right to
          terminate your account and Services and/or to permanently confiscate
          any Diamonds from your Blaze account that it determines, in its sole
          discretion, were acquired using fraudulent or otherwise improper
          means. This includes confiscation of any Diamonds which have been
          converted from Digital Gifts received by you from another user in
          which such Digital Gifts were purchased or received fraudulently.
          Blaze will notify you prior to such confiscation in order to provide
          an opportunity for you to make an appeal via the Services; provided,
          however, that Blaze’s determination, in its sole discretion, shall be
          final and binding on you. Any amounts confiscated from you in
          accordance herewith shall be reduced from your Diamonds balance.
          </div>

        </div>

        <div
          id="R"
          className="text-justify box-border font-medium text-white mt-5 mb-2.5"
          ref={subheadingRefs.R}
        >
          <h2 className=" text-white font-extrabold mt-10 text-lg">
            R. Payments and Refunds
          </h2>
          <div className="text-justify text-white box-border text-base">
            <strong>Payments:</strong>With an intent to have a more
            user-friendly transaction model for payment, we use third-party
            payment processors to process payments for products, services, or
            other content purchased through the Platform. We use Stripe Inc.
            (“Stripe”) for Followers situated outside India and Cashfree
            Payments Private Limited (Cashfree)for Followers situated in India.
            We use Razorpay for processing payouts to the Creators. As You may
            know, Stripe, Cashfree and Razorpay are third-party service
            providers, and third-party service providers (like Stripe, Razorpay
            and Cashfree) may also charge You fees to use or access their
            services and require Your personal data to complete any transaction
            for the Platform. Hence, prior to using Stripe, Cashfree or
            Razorpay, we suggest that You read their terms of service, privacy
            policy, and other policies and Razorpay’s terms and conditions,
            privacy policyand other policies, that may apply, to understand as
            to how Your data is being processed. Blaze is not affiliated with
            Stripe, Cashfree or Razorpay and neither Blaze nor Stripe or
            Razorpay or Cashfree are agents or employees of the other.
            <br />
            <br />
            In purchasing products or services through the Platform, a User may
            be redirected to an external website operated by Stripe, Razorpay or
            Cashfree (as applicable). We cannot and do not (i) guarantee the
            adequacy of the privacy and security practices employed by or the
            content and media provided by Stripe, Cashfree or Razorpay or its
            respective websites or (ii) control collection or use of Your
            personal information by Stripe, Cashfree or Razorpay. You agree that
            You are solely responsible for all charges that occur through a
            Stripe, Cashfree or Razorpay (as applicable) and acknowledge and
            agree to indemnify, defend, and hold harmless Blaze, its licensors,
            their corporate affiliates, and their respective officers,
            directors, employees, and agents from any loss arising out of or
            related to the use of the Platform or any purchases made through the
            Platform. This obligation will survive Your use of the Platform and
            termination of the Agreement. For purposes of this Agreement, “Loss”
            means all losses, liabilities, damages, awards, settlements, claims,
            suits, proceedings, costs and expenses (including reasonable legal
            fees and disbursements and costs of investigation, litigation,
            settlement, judgment, interest, and penalties). Blaze shall not be
            liable to You for any claims arising out of any act or omission on
            the part of Stripe, Cashfree or Razorpay (as the case may be)
            including, but not limited to, any lost, stolen, or incorrectly
            processed payments. Blaze expressly disclaims any responsibility and
            liability for all services provided by Stripe, Cashfree and
            Razorpay.
            <br />
            <br />
            (a). Payments with respect to Creators: To publish Your Content on
            the Platform and collect the applicable Creator Fees determined by
            You, You will need to create an account with Stripe or Razorpay (as
            applicable based on Your territory). When You use Stripe or
            Razorpay, You agree to their respective terms and policies. Stripe
            and/or Cashfree and/or Razorpay may charge a service fee to Creators
            on each transaction, and we suggest that You review Stripe’s and
            Razorpay’s and Cashfree's terms (respectively) from time to time to
            fully understand these charges. Further, to create an account with
            Stripe, it may require Your personal data. By using Stripe or
            Razorpay or Cashfree, You agree that any personal information
            provided by You or automatically collected from You by Stripe or
            Razorpay or Cashfree will be governed by the privacy policy, terms
            of use, or other applicable terms and conditions established by
            Stripe or Razorpay or Cashfree respectively and as applicable. While
            we understand that Stripe ensures that the credit card data is
            encrypted, prior to creating an account, we suggest that You please
            read Stripe’s terms of service, privacy policy, and other policies
            that may apply to understand as to how Your data is being processed.
            <br />
            <br />
            Once You’ve created Your Stripe account, You will see that Stripe
            will be responsible for directing and distributing payments for
            products and services purchased through the Platform, including
            automatically sending receipts to Followers and directing payouts to
            Creators and any bank accounts identified by Creators. Stripe or
            Razorpay or Cashfree (as applicable) will collect the amounts paid
            by the Followers and distribute it between You (i.e., the Creator)
            and Blaze (we get only the Platform Service Charge mentioned above).
            You will generate invoices to the Followers for the entire Creator
            Fee and You will receive invoices too on behalf of Blaze for the
            Platform Service Charge only. Also, please note the below terms-
            <br />● In respect of Your transaction, You are solely responsible
            for all applicable taxes, costs, filings, and any other activities
            that may arise as a result of sales of Your Content using the
            Platform. Neither is Blaze responsible to nor does it collect, pay,
            remit or otherwise pay on Your behalf, the taxes owed by You
            (including GST or EU VAT) on Content You sell through the Platform.
            <br />
            <br />
            <strong>
              <span>For Creators based in India</span>
            </strong>
            , the GST is applicable once they breach the threshold exemption (
            <strong>threshold exemption</strong> means Turnover of Rs. 20 Lacs
            or Rs. 10 Lacs in case of special category states) and need to,
            compulsorily, register themselves with the GST authorities and
            charge the prescribed GST from the users and pay the same to the GST
            authorities. You hereby agree to follow the rules, guidelines of the
            GST and absolve Blaze of any liability arising out of the said GST.{" "}
            <br />
            <br />
            <strong>
              In case you have failed to furnish the GST registration
              certificate to Blaze after crossing the threshold exemption on
              Blaze platform,
            </strong>
            <br />
            <br />{" "}
            <ul>
              <li>
                Blaze will start collecting 18% GST and start invoicing the
                customers on your behalf and deposit the same to the GST
                authorities.
                <br /> <strong>Billing:</strong> Blaze will generate GST
                invoices to the Followers for the entire Creator Fee and You
                will generate invoice to Blaze for an amount which is equal to
                (Creator Fee - Platform Service Charge).
                <br />
                <br />
                Once you submit the GST registration certificate to Blaze, you
                can start generating the invoices to the customers as usual and
                Blaze will generate an invoice to you of Platform Service charge
                only.
                <br />
                <br />
              </li>
            </ul>
            <strong>
              <span style={{ fontSize: "18px" }}>
                For Creators based outside India
              </span>
            </strong>
            , Blaze will collect GST from the customers and remit the same to
            the GST authorities.
            <br />
            <br /> <strong>Billing:</strong> Blaze will generate GST invoices to
            the Followers for the entire Creator Fee and You will generate
            invoice to Blaze for an amount which is equal to (Creator Fee -
            Platform Service Charge). <br />
            <br />
            Blaze will pay you the amount only when you provide us with duly
            filled & signed Form-10F, Tax Residency Certificate and NO Permanent
            Establishment Declaration Certificate. <br />
            ● Equlisation Levy @ 6% will be deducted on any payment made to the
            Foreign Creators/Creators not based in India against the Online
            Advertising Services provided by them to Blaze.
            <br />
            ● You set the Creator Fee of each Content published on the Platform
            at Your sole discretion and without any input from Blaze.
            <br />● All invoices made to Your Followers through Stripe or
            Razorpay or Cashfree (as applicable) shall indicate that You are the
            supplier of the Content.
            <br />● Followers’ invoices shall identify the name and nature of
            the Content that was purchased.
            <br />● As stated, Stripe and Razorpay and Cashfree are third-party
            service providers, hence, neither is Blaze a party to the
            authorization process nor is Blaze responsible for authorizing
            credit card charges through Stripe and/or Razorpay and/or Cashfree.
            <br />● You are responsible for Your Content's terms and conditions
            and are not bound by our refund policy, if any.
            <br />● Blaze has no responsibility to accept or reject Content
            enrolments should there be any issues with payments. You agree that
            once payments are processed through Stripe or Razorpay or Cashfree,
            such processing serves as an authorization to Blaze to deliver the
            Content materials to Followers through the Services.
            <br />
            <br />
            <strong>(b). Payments with respect to Followers: </strong>To sign-up
            for any Content, You will need to pay the Creator Fee determined by
            the respective Creator, which shall be processed by Stripe or
            Razorpay or Cashfree (as applicable), as explained above. Your
            payments may be subject to applicable taxes, so we suggest that You
            read terms and policies of Stripe and/or Razorpay and/or Cashfree(as
            applicable) to understand the same better. Further, please note that
            Blaze is only an intermediary between You and the Creator; in other
            words, we are only facilitating the transaction between You and the
            Creator through the Services offered on the Platform. Hence, we are
            not responsible for the Content of the Creator, the Creator Fees,
            payment processing or refunds. Your enrolment for any Content is
            subject to the terms and conditions determined by the respective
            Creator, at his/her/its sole discretion without suggestions from
            Blaze. Should You face any issues in this regard, please reach out
            to the respective Creator to resolve the same.
          </div>
        </div>

        <div
          id="S"
          className="text-justify box-border font-medium text-white mt-5 mb-2.5"
          ref={subheadingRefs.S}
        >
          <h2 className="text-white font-extrabold mt-10 text-lg">
            S. Deletion of Account
          </h2>

          <div className="text-justify text-white box-border text-base">
            You may delete Your account at any time. If Your account is deleted
            (regardless of the reason), Your Content may no longer be available
            and the same may be irrecoverable. Blaze is not responsible for the
            loss of such Content upon deletion and Blaze shall not be liable to
            any party in any way for the inability to access Content arising
            from any deletion.
          </div>
        </div>

        <div
          id="T"
          className="text-justify box-border font-medium text-white text-lg mt-5 mb-2.5"
          ref={subheadingRefs.T}
        >
          <h2 className=" text-white font-extrabold mt-10 text-lg">
            T. Disclaimer
          </h2>
          <div className="text-justify text-white box-border text-sm">
            YOU AGREE THAT THE PLATFORM, CONTENT, AND ALL MATERIALS ARE PROVIDED
            ON AN “AS IS” AND “AS AVAILABLE” BASIS AND YOUR USE OF THE PLATFORM
            SHALL BE AT YOUR SOLE RISK. TO THE FULLEST EXTENT PERMITTED BY LAW,
            Blaze, ITS OFFICERS, DIRECTORS, EMPLOYEES, AND AGENTS DISCLAIM AND
            EXCLUDE ALL WARRANTIES, EXPRESS OR IMPLIED, IN CONNECTION WITH THE
            PLATFORM AND YOUR USE THEREOF. TO THE FULLEST EXTENT PERMITTED BY
            LAW, Blaze EXCLUDES ALL WARRANTIES, CONDITIONS, TERMS OR
            REPRESENTATIONS ABOUT THE ACCURACY, SECURITY, RELIABILITY, QUALITY,
            AVAILABILITY OR COMPLETENESS OF THE PLATFORM, USER CONTENT ON THE
            PLATFORM, OR THE CONTENT OF ANY SITES SO LINKED AND ASSUMES NO
            LIABILITY OR RESPONSIBILITY FOR ANY (I) ERRORS, MISTAKES, OR
            INACCURACIES OF USER CONTENT OR ANY CONTENT ON THE PLATFORM, (II)
            PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER,
            RESULTING FROM YOUR ACCESS TO AND USE OF THE PLATFORM, (III) ANY
            UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR ANY AND
            ALL PERSONAL INFORMATION AND/OR FINANCIAL INFORMATION STORED
            THEREIN, (IV) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR
            FROM THE PLATFORM, (IV) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE
            LIKE WHICH MAY BE TRANSMITTED TO OR THROUGH THE PLATFORM BY ANY
            THIRD PARTY, AND/OR (V) ANY ERRORS OR OMISSIONS IN ANY USER CONTENT
            OR ANY OTHER CONTENT OR FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED
            AS A RESULT OF THE USE OF ANY USER CONTENT OR ANY OTHER CONTENT THAT
            IS POSTED, EMAILED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE
            PLATFORM. Blaze DOES NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME
            RESPONSIBILITY FOR ANY USER CONTENT OR THE PLATFORM ADVERTISED OR
            OFFERED BY A THIRD PARTY THROUGH THE PLATFORM OR ANY HYPERLINKED
            SERVICES OR FEATURED IN ANY BANNER OR OTHER ADVERTISING, AND Blaze
            WILL NOT BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING
            ANY TRANSACTION BETWEEN YOU AND THIRD-PARTY PROVIDERS OF USER
            CONTENT OR SERVICES. AS WITH THE PURCHASE OF A SERVICE THROUGH ANY
            MEDIUM OR IN ANY ENVIRONMENT, YOU SHOULD USE YOUR BEST JUDGMENT AND
            EXERCISE CAUTION WHERE APPROPRIATE.
          </div>
        </div>

        <div
          id="U"
          className="text-justify box-border font-medium text-white mt-5 mb-2.5"
          ref={subheadingRefs.S}
        >
          <h2 className=" text-white font-extrabold mt-10 text-lg">
            U. Limitation of Liability
          </h2>
          <div className="text-justify text-white box-border text-base">
            TO THE FULLEST EXTENT PERMITTED BY LAW, IN NO EVENT SHALL, Blaze,
            ITS OFFICERS, DIRECTORS, EMPLOYEES, OR AGENTS, BE LIABLE TO YOU FOR
            ANY DIRECT, INDIRECT, INCIDENTAL, EXEMPLARY, SPECIAL, PUNITIVE,
            LOSSES OR EXPENSES OR CONSEQUENTIAL DAMAGES WHATSOEVER ARISING OUT
            OF, RESULTING FROM, OR IN CONNECTION WITH YOUR USE, OR INABILITY TO
            USE, THE PLATFORM, ANY CONTENT ON THE PLATFORM, OR SUCH OTHER
            SERVICES OR ITEMS OBTAINED THROUGH THE PLATFORM INCLUDING, BUT NOT
            LIMITED TO ANY (I) ERRORS, MISTAKES, OR INACCURACIES OF USER CONTENT
            OR ANY OTHER CONTENT AVAILABLE ON THE PLATFORM, (II) PERSONAL INJURY
            OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR
            ACCESS TO AND USE OF OUR PLATFORM, (III) ANY UNAUTHORIZED ACCESS TO
            OR USE OF OUR SECURE SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION
            AND/OR FINANCIAL INFORMATION STORED THEREIN, (IV) ANY INTERRUPTION
            OR CESSATION OF TRANSMISSION TO OR FROM THE PLATFORM, (IV) ANY BUGS,
            VIRUSES, TROJAN HORSES, OR THE LIKE, WHICH MAY BE TRANSMITTED TO OR
            THROUGH OUR PLATFORMS BY ANY THIRD PARTY, AND/OR (V) ANY ERRORS OR
            OMISSIONS IN ANY USER CONTENT OR ANY OTHER CONTENT OR FOR ANY LOSS
            OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF YOUR USE OF ANY
            CONTENT POSTED, EMAILED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE
            VIA THE PLATFORM, WHETHER BASED ON WARRANTY, CONTRACT, TORT, OR ANY
            OTHER LEGAL THEORY, AND WHETHER OR NOT Blaze IS ADVISED OF THE
            POSSIBILITY OF SUCH DAMAGES.
            <br />
            <br />
            YOU SPECIFICALLY ACKNOWLEDGE THAT Blaze SHALL NOT BE LIABLE FOR USER
            CONTENT OR THE DEFAMATORY, OFFENSIVE, OR ILLEGAL CONDUCT OF ANY
            THIRD PARTY AND THAT THE RISK OF HARM OR DAMAGE FROM THE FOREGOING
            RESTS ENTIRELY WITH YOU.
            <br />
            <br />
            THE PLATFORM IS CONTROLLED AND OFFERED FREE OF CHARGE BY Blaze FROM
            ITS FACILITIES IN INDIA. Blaze MAKES NO REPRESENTATIONS THAT THE
            PLATFORM IS APPROPRIATE OR AVAILABLE FOR USE IN OTHER LOCATIONS.
            THOSE WHO ACCESS OR USE THE PLATFORM FROM OTHER JURISDICTIONS DO SO
            AT THEIR OWN VOLITION AND ARE RESPONSIBLE FOR COMPLIANCE WITH LOCAL
            LAW.
          </div>
        </div>

        <div
          id="V"
          className="text-justify box-border font-medium text-white text-lg mt-5 mb-2.5"
          ref={subheadingRefs.V}
        >
          <h2 className=" text-white font-extrabold mt-10 text-lg">
            V. Indemnity and Release
          </h2>
          <div className="text-justify text-white box-border text-sm">
            You agree to indemnify and hold harmless Blaze and its successors
            and assigns and all of their respective officers, directors,
            subsidiaries, affiliates, agents and employees, from any claims,
            liability, judgments, awards, losses, obligations, costs, expenses
            or fees (including reasonable attorneys’ fees), demands, or actions
            made by any third party or penalty imposed due to or arising out of
            Your breach of this Agreement or any document incorporated by
            reference, any claim that content provided by you caused damage or
            loss to a third party, or Your violation of any law, rules,
            regulations or the rights of a third party.
            <br />
            <br /> You hereby expressly release Blaze and any of its officers
            and representatives from any cost, damage, liability or other
            consequence of any of the actions/inactions of any third-party
            vendors or service providers and specifically waive any claims or
            demands that You may have in this behalf against Blaze under any
            statute, contract or otherwise.
          </div>
        </div>

        <div
          id="W"
          className="text-justify box-border font-medium text-white mt-5 mb-2.5"
          ref={subheadingRefs.W}
        >
          <h2 className=" text-white font-extrabold mt-10 text-lg">W. OFAC</h2>
          <div className="text-justify text-white box-border text-base">
            You represent and warrant that You are not, nor are You owned or
            controlled directly or indirectly by, any person, group, entity or
            nation named on any list issued by the Department of the Treasury's
            Office of Foreign Asset Control <strong>('OFAC')</strong>, or any
            similar list or by any law, order, rule or regulation or any
            Executive Order of the President of the United States, including
            Executive Order 13224, as 'Specially Designated National and Blocked
            Person', terrorist, or other banned or blocked person (any such
            person, group, entity or nation being hereinafter referred to as a{" "}
            <strong>('Prohibited Person')</strong> (ii) You are not (nor are You
            owned or controlled, directly or indirectly, by any person, group,
            entity or nation which is) acting directly or indirectly for or on
            behalf of any Prohibited Person; (iii) You (and any person, group,
            or entity which You control, directly or indirectly) have not
            conducted nor will conduct business nor have engaged nor will engage
            in any transaction or dealing with any Prohibited Person in
            violation of the U.S. Patriot Act or any OFAC rule or regulation,
            including, without limitation, the making or receiving of any
            contribution of funds, good or services to or for the benefit of a
            Prohibited Person in violation of the U.S. Patriot Act or any OFAC
            rule or regulation (iv) You are not prohibited by any sanctions
            program as maintained by OFAC from transacting with Blaze, including
            those sanctions programs currently in effect with respect to Cuba,
            Iran, North Korea, Syria, Venezuela, or Crimea. In connection with
            the foregoing, it is expressly understood and agreed that the
            representations and warranties contained in this subsection shall be
            continuing in nature.
          </div>
        </div>

        <div
          id="X"
          className="text-justify box-border font-medium text-white mt-5 mb-2.5"
          ref={subheadingRefs.X}
        >
          <h2 className=" text-white font-extrabold mt-10 text-lg">
            X. Other Communications
          </h2>
          <div className="text-justify text-white box-border text-base">
            When You visit the Platform, You are communicating with us
            electronically. You may be required to provide a valid phone number
            while creating any account with us or while placing an order with
            us. We may communicate with You by e-mail, SMS, phone call or by
            posting notices on the Platform or by any other mode of
            communication. For contractual purposes, You consent to receive
            communications (including transactional, promotional and/or
            commercial messages), from us with respect to Your use of the
            Platform and/or Your order placed on the Platform. These
            communications may be made by or on behalf of Blaze, even if Your
            phone number is registered on any state or federal Do Not Call list.
            You acknowledge that You may incur a charge for these texts or calls
            by Your telephone carrier and that Blaze will not be responsible for
            these charges.
          </div>
        </div>

        <div
          id="Y"
          className="text-justify box-border font-medium text-white mt-5 mb-2.5"
          ref={subheadingRefs.Y}
        >
          <h2 className=" text-white font-extrabold mt-10 text-lg">
            Y. Governing law and Jurisdiction
          </h2>
          <div className="text-justify text-white box-border text-base">
            This Agreement shall be governed by and construed in accordance with
            the laws of the State of Delaware, USA, and any disputes arising out
            of or related to this Agreement shall be subject to the exclusive
            jurisdiction of the courts located in Delaware.
          </div>
        </div>

        <div
          id="Z"
          className="text-justify box-border font-medium text-white mt-5 mb-2.5"
          ref={subheadingRefs.Z}
        >
          <h2 className=" text-white font-extrabold mt-10 text-lg">
            Z. Miscellaneous
          </h2>
          <div className="text-justify text-white box-border text-base">
            {" "}
            <strong>
              Alteration of Service or Amendments to the Conditions:
            </strong>{" "}
            We reserve the right to make changes to our Platform, policies, and
            this Agreement at any time. We will post the new terms with a
            revision date indicated at the top or if deemed practicable, we may
            notify You of such changes by sending an email to the email address
            then registered for Your account on the Platform, unless You have
            instructed us not to; notwithstanding the foregoing, You should
            check our Platform frequently to see recent changes. You will be
            subject to the policies and Agreement in force at the time that You
            use the Platform or that You order Blaze from us, unless any change
            to those policies or these conditions is required to be made by law
            or government authority (in which case it will apply to orders
            previously placed by You). If any of these conditions is deemed
            invalid, void, or for any reason unenforceable, that condition will
            be deemed severable and will not affect the validity and
            enforceability of any remaining condition.
            <br />
            <br />
            <strong>Waiver:</strong> If You breach these conditions and we take
            no action, we will still be entitled to use our rights and remedies
            in any other situation where You breach these conditions.
            <br />
            <br />
            <strong>Assignment:</strong> User may not assign or transfer this
            Agreement, by operation or law or otherwise, without Blaze’s prior
            written consent. Any attempt by the User to assign or transfer this
            Agreement without such consent, will be null and void. Blaze may
            assign or transfer this Agreement, at its sole discretion, without
            restriction. Subject to the foregoing, this Agreement will bind and
            inure to the benefit of each party’s permitted successors and
            assigns. <br />
            <br />
            <strong>Severability:</strong> If any provision of this Agreement
            will be adjudged by any court of competent jurisdiction to be
            unenforceable or invalid, that provision will be limited to the
            minimum extent necessary so that this Agreement will otherwise
            remain in effect.
            <br />
            <br />
            <strong>Events beyond our reasonable control:</strong> We will not
            be held responsible for any delay or failure to comply with our
            obligations under these conditions if the delay or failure arises
            from any cause which is beyond our reasonable control. This
            condition does not affect Your statutory rights. In other words,
            Blaze shall not be liable hereunder by reason of any failure or
            delay in the performance of its obligations hereunder, including any
            or all of the Services, during any period in which such performance
            is prevented or delayed due to <strong>Force Majeure</strong>.
            “Force Majeure” refers to any event beyond Blaze' reasonable
            control, including but not limited to acts of God, severe weather,
            fire, flood, mudslides, hurricanes, tornadoes, earthquakes, war,
            labor disputes, strikes, political unrest, natural or nuclear
            disaster, national security risks, pandemics, epidemics, World
            Health Organization’s advisories and/or alerts, Center for Disease
            Control’s advisories and/or alerts, U.S. State Department’s
            advisories and/or alerts, any order of any local, provincial or
            federal government authority, interruption of power services,
            terrorism or any other causes beyond the control of Blaze or deemed
            by Blaze to constitute a danger to the safety and well-being of
            Users.
            <br />
            <br />
            <strong>Losses: </strong>Blaze will not be responsible for any
            business loss (including loss of profits, revenue, contracts,
            anticipated savings, data, goodwill or wasted expenditure) or any
            other indirect or consequential loss at any point due to any reason
            whatsoever
          </div>
        </div>

        <div
          id="AA"
          className="text-justify box-border font-medium text-white mt-5 mb-2.5"
        >
          <h2 class=" text-white font-extrabold mt-10 text-lg">
            AA. Contact Us
          </h2>
          <div className="text-justify text-white box-border text-base">
            {" "}
            If You’ve have concerns or queries regarding this Agreement, You may
            write to us by email at{" "}
            <strong>
              <a href="mailto:connect@blazelive.app">connect@blazelive.app</a>
            </strong>
          </div>
          <p></p>
        </div>
      </div>
    </div>
  );
}

export default TermsOfUse;
