import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { UserProvider } from "./Components/Context/UserContext";
import { AuthUserProvider } from "./Components/Context/AuthUserContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <AuthUserProvider>
  {/* // <UserProvider> */}
    <BrowserRouter>
      {/* <React.StrictMode> */}
      <App />
      {/* </React.StrictMode> */}
    </BrowserRouter>
  {/* // </UserProvider> */}
  </AuthUserProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
