import StreamingBlaze1 from "../assets/icons/StreamingBlaze1.png";
import BlazeIconLogo from "../assets/icons/blazeiconlogo.png";
import UserIcon from "../assets/icons/usericon.png";
import WalletIcon from "../assets/icons/WalletIcon.png";
import HamBtn from "../assets/icons/hamburgericon.png";
import ImgClipArt from "../assets/icons/ImgClipArt.png";
import AppleStore from "../assets/images/AppleStore.png";
import PlayStore from "../assets/images/PlayStore.png";
import GroupSnap from "../assets/images/GroupSnap.png";
import Stars from "../assets/icons/stars.png";
import Facebook from "../assets/icons/facebook.png";
import Twitter from "../assets/icons/twitter.png";
import Instagram from "../assets/icons/instagram.png";
import Youtube from "../assets/icons/youtube.png";
import Dot from "../assets/icons/Dot.png";
import BlazeMainScr from "../assets/images/BlazeMainScr.png";
import Circles from "../assets/images/circles.png";
import LoginSignUp from "../assets/icons/login-signup.png";
import MobileIcon from "../assets/icons/MobileIcon.png";
import coins from "../assets/icons/coins.png";
// import outerribboncelebrate from "../assets/icons/outerribboncelebrate.png";
// import innersalecircle from "../assets/icons/innersalecircle.png";
import LimitedOffer40 from "../assets/images/LimitedOffer40.png";
import Mic from "../assets/icons/Mic.png";
import Ninja from "../assets/icons/Ninja.png";
import Celebration from "../assets/icons/Celebration.png";
import Joystick from "../assets/icons/Joystick.png";
import Iph12Icons from "../assets/images/Iph12Icons.png";
import AboutUsImg from "../assets/images/AboutUsImg.png";
import OurMissonImg from "../assets/images/OurMissonImg.png";
import CreativityIdea from "../assets/icons/CreativityIdea.png";
import Accesseblity from "../assets/icons/Accesseblity.png";
import Community from "../assets/icons/Community.png";
import Innovation from "../assets/icons/Innovation.png";
import BlogsImage from "../assets/images/BlogsImage.png";
import BlogCardImg1 from "../assets/images/BlogCardImg1.png";
import BlogThumbnailImg from "../assets/images/BlogThumbnailImg.png";
import AboutUsImgLarge from "../assets/images/AboutUsImgLarge.png";
import OurMissionImgLarge from "../assets/images/OurMissionImgLarge.png";

//Promotional Coin
import PrCoinBag from "../assets/icons/prcoinbg.png"
import PcIcon from "../assets/icons/pcicon.png"
// coinimages style1
import CoinsImg from "../assets/icons/coinsImg/CoinStyle1/coins.png"
import TenThousandCoin from "../assets/icons/coinsImg/CoinStyle1/10Kcoin.png";
import FiveThousandCoin from "../assets/icons/coinsImg/CoinStyle1/5Kcoin.png";
import ThousandCoin from "../assets/icons/coinsImg/CoinStyle1/1Kcoin.png";
import FiveHundredCoin from "../assets/icons/coinsImg/CoinStyle1/500coin.png";
import TwoHundredCoin from "../assets/icons/coinsImg/CoinStyle1/200coin.png";
import HundredCoin from "../assets/icons/coinsImg/CoinStyle1/100coin.png";

// coinimages style2
import TenThousandCoinBag from "../assets/icons/coinsImg/CoinStyle2/10Kcoin.png";
import FiveThousandCoinBag from "../assets/icons/coinsImg/CoinStyle2/5Kcoin.png";
import ThousandCoinBag from "../assets/icons/coinsImg/CoinStyle2/1Kcoin.png";
import FiveHundredCoinBag from "../assets/icons/coinsImg/CoinStyle2/500coin.png";
import TwoHundredCoinBag from "../assets/icons/coinsImg/CoinStyle2/200coin.png";
import HundredCoinBag from "../assets/icons/coinsImg/CoinStyle2/100coin.png";
import DefaultCoinBag from "../assets/icons/coinsImg/CoinStyle2/100coin.png";

//
import WebSignUpImg from "../assets/images/WebSignUpImg.png";
import SignUpImg from "../assets/images/signup.png"
import GoogleIcon from "../assets/icons/google.png";
import FacebookIcon from "../assets/icons/facebook.png";
import FaceBookLogo from "../assets/icons/facebook-logo.png"

import MyBalanceCoinIcon from "../assets/icons/coinicon.png"
import MyBalanceAddCoin from "../assets/icons/addcoinicon.png"
import Disha from "../assets/images/disha.png"

import upiIcon from "../assets/icons/upiicon.png"
//faq icons
import DiamondsPNG from '../assets/icons/diamonds.png'
import LivestreamPNG from '../assets/icons/livestream.png'
import GiftsPNG from '../assets/icons/Gifts.png'
import AccountsPNG from '../assets/icons/account.png'
import OthersPNG from '../assets/icons/others.png'
import BannerProm from '../assets/images/BannerProm.png'


import BlazeMobileBanner from '../assets/images/banner12pro.png'
import AppleStorePNG from "../assets/images/AppleStore.png"
import PlayStorePNG from "../assets/images/PlayStore.png"
import Logo from "../assets/images/BlazeLogo.png"
import logo from "../assets/icons/blazeicon.png"
import BlazeIcons from '../assets/images/iconGroup.png'
import BlazeLanding from '../assets/images/BlazeLanding.png'
import BlazeLandingMobile from '../assets/images/BlazeLandingMobile.png'
import payStripePass from '../assets/icons/stripePaySuccess.png'
import payStripeFail from '../assets/icons/stripePayFailed.png'





export default {
  StreamingBlaze1,
  BlazeIconLogo,
  UserIcon,
  WalletIcon,
  HamBtn,
  ImgClipArt,
  AppleStore,
  PlayStore,
  GroupSnap,
  Stars,
  Facebook,
  Twitter,
  Instagram,
  Youtube,
  Dot,
  Circles,
  BlazeMainScr,
  LoginSignUp,
  MobileIcon,
  coins,
  LimitedOffer40,
  Mic,
  Ninja,
  Celebration,
  Joystick,
  Iph12Icons,
  AboutUsImg,
  OurMissonImg,
  CreativityIdea,
  Accesseblity,
  Community,
  Innovation,
  BlogsImage,
  BlogCardImg1,
  BlogThumbnailImg,
  AboutUsImgLarge,
  OurMissionImgLarge,
  //general coin
  CoinsImg,
  PrCoinBag,
  PcIcon,
  // coins style1
  TenThousandCoin,
  FiveThousandCoin,
  ThousandCoin,
  FiveHundredCoin,
  TwoHundredCoin,
  HundredCoin,
  //coins style2
  TenThousandCoinBag,
  FiveThousandCoinBag,
  ThousandCoinBag,
  FiveHundredCoinBag,
  TwoHundredCoinBag,
  HundredCoinBag,
  DefaultCoinBag,

  WebSignUpImg,
  GoogleIcon,
  FacebookIcon,
  FaceBookLogo,

  MyBalanceAddCoin,
  MyBalanceCoinIcon,
  Disha,
  upiIcon,
  SignUpImg,

  //faq icons
  DiamondsPNG,
  LivestreamPNG,
  GiftsPNG,
  AccountsPNG,
  OthersPNG,
  BannerProm,


  BlazeMobileBanner,
  AppleStorePNG,
  PlayStorePNG,
  Logo,
  logo,
  BlazeIcons,
  BlazeLanding,
  BlazeLandingMobile,
  payStripeFail,
  payStripePass,


};
