import React from "react";

function ContactUs() {
  return (
    <div className="text-white flex flex-col justify-center text-center items-center px-4">
      <p className="text-xl md:text-2xl lg:text-3xl font-bold">Contact Us</p>
      <div className="py-4 text-base">
        <p>Entechprise, Inc</p>
        <p>
          Address : 8 The Green, STE A City of Dover, County of Kent, Delaware,
          US- 19901
        </p>
        <p>Support : support@blazelive.app</p>
        <p>Phone : +1-3026001087</p>
      </div>
    </div>
  );
}

export default ContactUs;
