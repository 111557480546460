import React from 'react';
import images from '../Constants/images';

const PaymentFailed = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen text-white">
      <div className="flex flex-col items-center mb-8">
        {/* add img below */}
        <div className="text-4xl text-red-500 mb-4">
            <img src={images.payStripeFail}/>
        </div>
        <h1 className="text-2xl font-bold">Payment Failed</h1>
        <p className="mt-2">Your payment has failed. Please try again or contact our support.</p>
      </div>

      <div className="bg-gray-800 p-6 rounded-lg shadow-lg w-full max-w-md">
        <div className="flex justify-between mb-4">
          <span>Purchased</span>
          <span>₹1000</span>
        </div>
        <div className="flex justify-between mb-4">
          <span>Existing</span>
          <span>₹300</span>
        </div>
        <hr className="border-gray-600" />
        <div className="flex justify-between mt-4 font-bold">
          <span>Total</span>
          <span>₹1300</span>
        </div>
      </div>

      <button className="mt-8 bg-gradient-to-r from-purple-500 to-blue-600 text-white font-semibold py-2 px-4 rounded-lg shadow-lg hover:opacity-90 transition duration-300">
        Retry Payment
      </button>

      <button className="mt-4 bg-gray-800 text-white font-semibold py-2 px-4 rounded-lg shadow-lg hover:opacity-90 transition duration-300">
        Contact Support
      </button>
    </div>
  );
};

export default PaymentFailed;