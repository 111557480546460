import React, { useState, useEffect, useContext } from "react";
// import "./promotionalrecharge.css";
import images from "../Constants/images";
import PRCoinBag from "./PRCoinBag";
import { getProductsList, coinRecharge } from "../apiUtils";
import { AuthUserContext } from "./Context/AuthUserContext"; // Adjust the import path as necessary

export default function PromotionalRecharge() {
  const { authToken } = useContext(AuthUserContext); // Use AuthUserContext to get authToken
  const [webProducts, setWebProducts] = useState({});
  const [walletBalance, setWalletBalance] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProducts = async () => {
      if (!authToken) {
        console.error("Token is missing, skipping fetch");
        setLoading(false);
        setError("Token is missing");
        return;
      }

      try {
        setLoading(true);
        const data = await getProductsList(authToken);

        if (data.status === "ok") {
          setWebProducts(data.products.web || {});
          setWalletBalance(data.wallet.balance || 0);
        } else {
          console.error("Failed to fetch products:", data.message);
          setError(data.message || "Failed to fetch products");
        }
      } catch (error) {
        console.error("Error fetching products:", error);
        setError("Error fetching products");
      } finally {
        setLoading(false);
      }
    };

    const debounceFetch = setTimeout(() => {
      fetchProducts();
    }, 300); // 300ms debounce time

    return () => clearTimeout(debounceFetch);
  }, [authToken]);

  const handleCoinBagClick = async (productId) => {
    if (!authToken) {
      console.error("Token is missing, cannot handle coin bag click");
      return;
    }

    try {
      await coinRecharge(authToken, "default", productId);
    } catch (error) {
      console.error("Error during coin recharge:", error);
    }
  };

  if (loading) {
    return (
      <div className="loading-container text-center">
        <p className="text-white">Loading...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="error-container text-center">
        <p className="text-white">Error: {error}</p>
      </div>
    );
  }

  const products = webProducts.default || {};

  return (
    <div
      className="promotional-recharge-container"
      style={{
        backgroundImage: `url(${require("../assets/PromotionalRcbg.png")})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        minHeight: "100vh", // To ensure it covers the full height of the page
        width: "100vw", // Full viewport width
        backgroundPosition: "center",
        backgroundAttachment: "fixed", // Ensures the background stays in place during scroll
        zIndex: 2, // Keeps it above the global background if necessary
        opacity:1
      }}
    >
      <div className="text-white flex flex-col gap-4 items-center py-10">
        <p className="text-base md:text-3xl font-bold">
          ⭐️Get 40% off on any coin purchase⭐️
        </p>
        <p className="text-base md:text-xl">
          Recharge through UPI and get 10% coins
        </p>
      </div>
      <div className="flex flex-col gap-4 items-center mt-2">
        <p className="text-sm md:text-3xl text-white">Available Balance</p>
        <div className="flex items-center gap-2 pb-5">
          <img
            src={images.MyBalanceCoinIcon}
            className="h-5 w-5 sm:h-8 sm:w-8"
            alt="Coin Icon"
          />
          <p className="text-center text-lg text-white">{walletBalance}</p>
        </div>
      </div>

      <div className="flex flex-col gap-5 px-4 md:gap-10">
        {Object.keys(products).length > 0 ? (
          <div className="flex flex-wrap justify-center items-center gap-5 md:gap-10">
            {Object.keys(products).map((productId) => {
              const product = products[productId];
              return (
                <PRCoinBag
                  key={productId}
                  coinValue={product.card.coin}
                  discountedPrice={product.card.inr}
                  actualPrice={product.card.usd}
                  onClick={() => handleCoinBagClick(productId)}
                />
              );
            })}
          </div>
        ) : (
          <p className="text-white">No web products available</p>
        )}
      </div>
    </div>
  );
}
