import React from "react";
import images from "../../Constants/images";
import "../Recharge/coincard.css";

const CoinCard = (props) => {
  const {
    rechargecoinimage,
    rechargenumeration,
    rechargecost,
    // rechargeoldcost,
  onClick} = props;
  return (
    <div onClick={onClick}
      style={{ aspectRatio: 3 / 4 }}
      className="flex flex-col w-full items-center justify-center bg-black rounded-lg "
    >
      <div
        style={{ width: 100, height: 100 }}
        className="flex justify-center items-center"
      >
        <img
          src={rechargecoinimage}
          style={{ objectFit: "fill" }}
          height={"100%"}
          width={"100%"}
        />
      </div>

      <div>
        <p className="text-white text-xl white text-center whitespace-nowrap">
          {rechargenumeration}
        </p>
      </div>

      {/* wrapp below div in button for Card Button */}
      <div className="Coin-Card-Btn mt-3 mb-2">
        <p className="text-white text-sm text-center whitespace-nowrap px-4 py-1">
          {rechargecost}
        </p>
      </div>
    </div>
  );
};

export default CoinCard;
