import React from "react";

const SingleCard1 = (props) => {
  const { clipart, heading, paragh } = props;

  return (
    <div className="card1-v2 flex pt-10 pb-10 items-center m-auto w-11/12 h-76 text-white gap-5 pl-6 rounded-lg">
      <img src={clipart} className={"hidden md:flex h-16 w-16 mb-4 pb-6 object-contain"} />
      <div className="flex flex-col">
        <p className="text-base md:text-lg font-bold">{heading}</p>
        <p className="pr-5 text-base pt-2">{paragh}</p>
      </div>
    </div>
  );
};

export default SingleCard1;
