import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AuthUserContext } from "../Context/AuthUserContext"; // Adjust the import path as necessary
import AppStoreLinks from "../AppStoreLinks";
import "../BlazeS6/blazescreen6.css";
import images from "../../Constants/images";
import { AiFillCheckCircle } from "react-icons/ai";

import BlazeScreen4 from "../BlazeS4/BlazeScreen4";
import BlazeScreen5 from "../BlazeS5/BlazeScreen5";
import BlazeScreen6 from "../BlazeS6/BlazeScreen6";
import Recharge from "../Recharge/Recharge";
import RechargeBanner from "../RechargeBanner";

const HomePage = () => {
  // const { authToken, user } = useContext(AuthUserContext); // Use AuthUserContext to get authToken
  // const navigate = useNavigate();

  // const handleButtonClick = () => {
  //   if (authToken && user) {
  //     navigate("/PromotionalRecharge");
  //     // console.log("should go to Login Page ");
  //   } else {
  //     // Redirect to login page with a query parameter indicating the desired return path
  //     navigate("/Login", { state: { nextPath: "/PromotionalRecharge" } });
  //     // console.log("coming here instead");
  //   }
  // };

  return (
    <div className="flex flex-col">
      <div className="sm:flex sm:justify-between sm:items-center flex-row-reverse px-20">
        {/* <div className="md:flex-1 md:pt-10">
          <img src={images.BlazeLanding} alt="Main" />
        </div> */}

        <div className="">
          <img src={images.BlazeLandingMobile} alt="MainMobile" />
        </div>

        <div className="flex flex-col justify-between items-center sm:items-start sm:mx-20">
          <div className="text-4xl lg:text-8xl flex flex-col items-center font-black sm:flex sm:items-start ">
            <p className="bg-clip-text text-transparent bg-gradient-to-r from-[#642eff] to-[#d52eff]">
              Stream
            </p>
            <p className="bg-clip-text text-transparent bg-gradient-to-r from-[#bcff66] to-[#04d277]">
              Live
            </p>
            <p className="whitespace-nowrap bg-clip-text text-transparent bg-gradient-to-r from-[#ffc266] to-[#ff1e78]">
              Feel Alive
            </p>
          </div>
          <div className="flex flex-col justify-between flex-wrap items-center text-xs gap-2 md:items-start sm:text-left lg:text-2xl">
            <p className="text-white font-bold text-center text-base sm:text-left md:text-2xl py-2">
              Follow your passion and grow your community with Live Shows
            </p>
            <p className="text-white pt-5 text-base md:text-lg">Download the App</p>
            <AppStoreLinks />
          </div>
        </div>
        {/* </div> */}
      </div>

      {/* <RechargeBanner imageUrl="" title="" description="" /> */}
      {/* <div onClick={handleButtonClick}>
        <div className="w-2/5 m-auto">
          <img
            src="https://images.freeimages.com/image/previews/762/sunset-cowboy-ride-5690199.jpg"
            className="w-full h-auto rounded-xl"
            alt="Limited Offer"
          />
        </div>
      </div> */}

      <div className="my-8 flex items-center gap-14 justify-center px-5 md:px-40">
      <div className="flex flex-col items-center gap-5">
        <div className="">
          <img
            src={images.GroupSnap}
            alt="grp-snap.jpg"
            className="object-cover h-[280px] w-[305px] md:h-[582px] md:w-[650px]" // Adjust height as needed
          />
        </div>
        <div className="md:hidden">
          <h1 className="font-black text-2xl py-4 md:text-5xl text-left bg-clip-text text-transparent bg-gradient-to-r from-[#d52eff] to-[#642eff]">
            Blaze - Experience the future of live streaming
          </h1>
          <p className="text-base md:text-lg lg:text-xl text-left text-white">
            Welcome to Blaze, the ultimate platform for live streaming and
            social connection. Whether you're a content creator, an artist, or
            simply want to connect with like-minded people, Blaze has everything
            you need to build your audience and grow your community.
          </p>
        </div>
        </div>
        {/* <div className="hidden md:flex flex-col gap-5 md:w-[542px]">
          <h1 className="font-black text-lg md:text-5xl py-4 text-left bg-clip-text text-transparent bg-gradient-to-r from-[#d52eff] to-[#642eff]">
            Blaze - Experience the future of live streaming
          </h1>
          <p className="text-base md:text-lg lg:text-xl text-left text-white">
            Welcome to Blaze, the ultimate platform for live streaming and
            social connection. Whether you're a content creator, an artist, or
            simply want to connect with like-minded people, Blaze has everything
            you need to build your audience and grow your community.
          </p>
        </div> */}
      </div>
      <div className="my-8">
        <BlazeScreen4 />
      </div>
      <div className="my-8">
        <BlazeScreen5 />
      </div>
      {/* <div className="relative flex flex-1 justify-center">
        <img
          src={images.BlazeMobileBanner}
          alt="Background"
          className="w-full h-auto object-contain"
          style={{
            maxHeight: "400px", // Adjust this height as necessary for your layout
          }}
        />

        <div className="absolute inset-0 flex flex-col justify-center items-start p-8">
          <div className="mt-2">
            <p className="text-sm font-extrabold text-white">
              Experience the future of live streaming
            </p>
          </div>

          <div className="flex flex-col gap-2 text-xs">
            <div className="checkers-text flex items-center">
              <AiFillCheckCircle />
              <p>Seamless experience</p>
            </div>
            <div className="checkers-text flex items-center">
              <AiFillCheckCircle />
              <p>Interactive features</p>
            </div>
            <div className="checkers-text flex items-center">
              <AiFillCheckCircle />
              <p>Engaging community</p>
            </div>
          </div>

          <div>
            <p className="text-white text-xs">Download the App</p>
            <AppStoreLinks />
          </div>
        </div>
      </div> */}
      <div className="my-10">
        <BlazeScreen6 />
      </div>
    </div>
  );
};

export default HomePage;
