import React, { useContext, useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { verifyOtp } from "../../apiUtils"; // Correct import statement for verifyOtp
import { AuthUserContext } from "../Context/AuthUserContext"; // Adjust path as needed
import images from "../../Constants/images";
import OtpInput from "react-otp-input";
import AppStoreLinks from "../AppStoreLinks";

const LoginOTP = () => {
  const { user } = useContext(AuthUserContext); // Use AuthUserContext here
  const [otp, setOtp] = useState("");
  const [resendTimer, setResendTimer] = useState(30); // Timer for OTP resend
  const navigate = useNavigate();
  const location = useLocation();
  const { mobile, identifier } = location.state || {}; // Get both mobile and identifier from navigation state
  const { updateAuth } = useContext(AuthUserContext);


  useEffect(() => {
    if (resendTimer > 0) {
      const timer = setInterval(() => setResendTimer((prev) => prev - 1), 1000);
      return () => clearInterval(timer);
    }
  }, [resendTimer]);

  const handleVerifyOtp = async () => {
    try {
      const { identifier } = location.state; // Get the identifier (username or mobile or email) from state

      if (!identifier) {
        console.error("Identifier is missing.");
        return;
      }

      const verifyResponse = await verifyOtp(identifier, otp);

      if (verifyResponse.status === "ok") {
        const { token, user } = verifyResponse;

        // Use updateAuth to store the token and user data in context and localStorage
        updateAuth(token, user);

        navigateToHome(); // Navigate to home on successful login
      } else {
        console.error("OTP verification failed:", verifyResponse.message);
      }
    } catch (error) {
      console.error("OTP verification error:", error);
    }
  };

  const navigateToHome = () => {
    navigate("/Home");
  };

  return (
    <div className="relative">
      {/* <Layout/> */}

      <div className="flex justify-center items-center md:px-10 px-4">
        <div
          className="text-white flex flex-col items-start gap-5 md:justify-center"
          style={{ flex: 0.4 }}
        >
          <div className="text-center md:text-left">
            <p className="signup-heading1 text-3xl md:text-6xl lg:text-7xl xl:text-8xl font-bold">
              Stream Live
            </p>
            <p className="signup-heading2 text-3xl md:text-6xl lg:text-7xl xl:text-8xl font-bold">
              Feel Alive
            </p>
          </div>
          <div className="text-base md:text-lg">
            <p className="text-center md:text-left">Verify Code</p>
            <p className="text-center md:text-left">We have sent a 4-digit code to {mobile && <span>{mobile}</span>} </p>
          </div>
          <div className="flex flex-col sm:w-full md:w-3/4 lg:w-2/3 xl:w-1/2">
            {/* Add OTP input boxes here */}
            <OtpInput
              value={otp}
              onChange={setOtp}
              numInputs={4}
              containerStyle={{
                width: "100%",
                display: "flex",
                justifyContent: "start",
                gap: "10px",
              }}
              inputStyle={{
                width: "40px",
                height: "40px",
                borderColor: "grey",
                borderWidth: 2,
                borderRadius: 8,
                color: "black",
              }}
              renderInput={(props) => <input {...props} />}
            />

            <p className="pt-2 text-base md:text-lg">Resend OTP in {resendTimer} sec</p>

            <button
              className="signup-continue text-base md:text-lg font-bold mt-4 w-full px-4 py-3 rounded-md text-white"
              style={{
                background:
                  "linear-gradient(90deg, rgba(100, 46, 255, 1) 0%, rgba(213, 46, 255, 1) 100%)",
              }}
              onClick={handleVerifyOtp} // Add onClick handler here
            >
              Login
            </button>
          </div>
          {/* <p className="text-xs mt-4 text-center md:text-left">
            Already have an account?
            <span className="signin-link">Sign In here</span>
          </p> */}
          {/* <div className="text-xs text-center md:text-left mt-4">
            <p>By continuing, you agree to our</p>
            <p>Terms and Conditions, Private Policies, and Content Policies</p>
          </div> */}

          <div className="md:hidden">
            <p
              className="text-center md:text-left text-base md:text-lg py-2"
              style={{ color: "#FFFFFF", marginTop: 20 }}
            >
              Download the App
            </p>
              <AppStoreLinks/>
          </div>
        </div>

        {/* Circles Container */}
        <div
          className="hidden md:flex flex-col justify-center items-center rounded-l-2xl"
          style={{ flex: 0.4, height: "100%", minHeight: "100%" }}
        >
          <div>
          <img src={images.BlazeLandingMobile} alt="Blaze Main Screen" />
          </div>
          <div className="hidden md:flex flex-col items-center">
            <p
              className="text-center text-base md:text-left"
              style={{ color: "#FFFFFF", marginTop: 20 }}
            >
              Download the App
            </p>
              <AppStoreLinks/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginOTP;
