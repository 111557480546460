import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AuthUserContext } from "./Context/AuthUserContext"; // Adjust the import path as necessary

const RechargeBanner = ({ imageUrl, title, description }) => {
  const { authToken, user } = useContext(AuthUserContext); // Use AuthUserContext to get authToken
  const navigate = useNavigate();

  const handleButtonClick = () => {
    if (authToken) {
      navigate("/PromotionalRecharge");
      // console.log("RechargeNow button clicked -")
    } else {
      navigate("/Login", { state: { nextPath: "/PromotionalRecharge" } });
      // console.log("else part ran")
    }
  };

  return (
    <div
      className="relative flex items-center justify-center h-full w-60 bg-cover bg-center, overflow-clip"
      style={{ backgroundImage: `url(${imageUrl})`, height: "200px", width:"50%",margin:'auto',borderRadius:'20px' }} // Adjust height as needed
    >
      <div className="absolute inset-0 flex items-center justify-center">
        <div className="text-center text-gray-500 z-50">
          <p className="text-2xl md:text-4xl font-extrabold">{title}</p>
          <p className="text-base md:text-lg font-semibold mt-2">{description}</p>
         
        </div>
      </div>
    </div>
  );
};

export default RechargeBanner;
